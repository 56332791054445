import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { MdDelete, MdVerified } from "react-icons/md";
import { handleDeleteCuisine } from "../../pages/cuisines/CuisinesList";



export const userColumns = ({ setCuisinesData }) => {
    return [
        {
            name: "Cuisine ID",
            selector: (row) => row.cuisine_id,
            cell: (row) => (
                <span>{row.cuisine_id}</span>
            ),
            sortable: true,
        },
        {
            name: "Cuisine Type",
            selector: (row) => row.cuisine_type,
            cell: (row) => (
                <span>{row.cuisine_type}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "Created Date",
            selector: (row) => row.createdAt,
            cell: (row) => (
                <span>{new Date(row.createdAt).toDateString()}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
       
       
    
        
        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                     <LinkListItem to={`/cuisines-manage/cuisines-list/${row.cuisine_id}`}>
                                        <Icon name="edit"></Icon><span>Edit</span>
                                    </LinkListItem>
    
                                    <LinkListItem to="#" className="delete-ittem" onClick={() => handleDeleteCuisine(row.cuisine_id, setCuisinesData)}>
                      <MdDelete/><span>Delete</span>
                    </LinkListItem>
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,
    
        },
    ]
};


