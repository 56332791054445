import { useEffect } from "react";
import { useNavigate, Outlet } from 'react-router-dom'
export default function AutenticationCheck(){
    const token = localStorage.getItem("token");
    const navigate = useNavigate()
    
    useEffect(() => {
        if(token) {
            navigate("/home-ecommerce")
        }
        else {
            navigate("/auths/auth-login");
        }
    },[token])
    return (
        <Outlet />
    );
}