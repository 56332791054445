import { useEffect, useRef } from 'react';
import pureknob from '../../assets/js/pureknob/pureknob.js';
import { Colors } from '../../utilities/index';

function Pureknob({ data, className, ...props }) {
  const knobRef = useRef(null);

  useEffect(() => {
    if (knobRef.current) {
      const size = data.size || 100;
      const angleStart = data.angleStart || 1;
      const angleEnd = data.angleEnd || 1;
      const angleOffset = data.angleOffset || false;
      const colorBg = data.colorBg || Colors.gray200;
      const colorFg = data.colorFg || Colors.primary;
      const trackWidth = data.trackWidth || 0.2;
      const min = data.min || 0;
      const max = data.max || 100;
      const readonly = data.readonly || true;
      const value = parseInt(data.value) || 0;
      const hideValue = data.hideValue ? 0 : 1;

      // Create knob element, by given value size.
      const knob = pureknob.createKnob(size, size);

      // Set properties.
      knob.setProperty('angleStart', -angleStart * Math.PI);
      knob.setProperty('angleEnd', angleEnd * Math.PI);
      knob.setProperty('angleOffset', angleOffset * Math.PI);
      knob.setProperty('colorFG', colorFg);
      knob.setProperty('colorBG', colorBg);
      knob.setProperty('trackWidth', trackWidth);
      knob.setProperty('valMin', min);
      knob.setProperty('valMax', max);
      knob.setProperty('readonly', readonly);
      knob.setProperty('textScale', hideValue);

      // Set initial value.
      knob.setValue(value);

      // Create element node.
      const node = knob.node();

      // Clear previous content.
      knobRef.current.innerHTML = '';

      // Add it to the DOM.
      knobRef.current.appendChild(node);
    }
  }, [data]);

  return <div ref={knobRef} className={className} />;
}

export default Pureknob;
