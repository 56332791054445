import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import DataTable from "../../components/DataTable/DataTable";
import React, { useLayoutEffect } from "react";
import AppRoot from "../../layout/global/AppRoot";
import AppMain from "../../layout/global/AppMain";
import AppWrap from "../../layout/global/AppWrap";
import AppContent from "../../layout/global/AppContent";
import { Card, Button, Modal, Form, Row, Col, Pagination } from "react-bootstrap";
import Header from "../../layout/default/Header";
import Sidebar from "../../layout/default/Sidebar";
import Footer from "../../layout/default/Footer";
import LayoutProvider from "../../layout/default/LayoutProvider";
import "../../assets/scss/main.scss";
import axios from "axios";
import { IoRestaurant } from "react-icons/io5";
import { userColumns } from '../../store/orders/OrdersData';
import { BsCardHeading, BsTable } from "react-icons/bs";
import { set } from "date-fns";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import instance from "../../api";
// import * as XLSX from 'xlsx';
const OrderList = (title, content, ...props) => {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    useLayoutEffect(() => {
        document.title = `${title} - Mom's Foood`;
    });

    const [ordersData, setOrdersData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage] = useState(8);
    const [activeTab, setActiveTab] = useState("ListView");
    const { restaurant_id } = useParams();
    const [ufilteredData, setUfilteredData] = useState(ordersData);
    const [page, setpage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [UsersFullData, setUsersFullData] = useState([]);
    const [order_id, setOrder_id] = useState("");

    const fetchData = async (restaurant_id, page, rowsPerPage, order_id) => {
        try {
            let apiUrl = `/Admin/orders?pageSize=${rowsPerPage}&page=${page}&order_id=${order_id}`;


            if (restaurant_id) {
                apiUrl = `/Order/restaurent/${restaurant_id}`;
            }

            const response = await instance.get(apiUrl);
            console.log(response);

            setOrdersData(response.data.rows);
            setUsersFullData(response.data);
            console.log(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {

        fetchData(restaurant_id, page, rowsPerPage, order_id);
    }, [restaurant_id, page, rowsPerPage, order_id]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [searchText, setSearchText] = useState('');

    const [searchBy, setSearchBy] = useState({ drill: 'User', searchList: ['full_name'] });

    const [searchKey, setSearchKey] = useState('full_name');

    const [filtereData, setFilteredData] = useState([]);
    // const exportToExcel = () => {
    //     // Create a worksheet from the data array
    //     const worksheet = XLSX.utils.json_to_sheet(ordersData);

    //     // Create a new workbook
    //     const workbook = XLSX.utils.book_new();

    //     // Append the worksheet to the workbook
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    //     // Generate Excel file and trigger download
    //     XLSX.writeFile(workbook, "orders_data.xlsx");
    //   };
    function handleSearchBy(ev) {
        // console.log(ev.target.value)
        if (ev.target.value === 'User') { setSearchBy({ drill: 'User', searchList: ['full_name'] }) }
        // if(ev.target.value === 'Seller'){setSearchBy({drill:'Restaurant', searchList:['name']})}
        else if (ev.target.value === 'Restaurant') { setSearchBy({ drill: 'Restaurant', searchList: ['name'] }) }
    }

    console.log(filtereData);
    useEffect(() => {
        let filteredData
        if (!searchText) {
            console.log('hi')
            setFilteredData(ordersData)
            return
        }
        else if (searchBy.drill) {
            console.log('user')
            filteredData = ordersData.filter(
                item => searchBy.searchList.reduce((total, search) => total || item[searchBy.drill][search]?.toLowerCase()?.includes(searchText?.toLowerCase()), false)
            );
        } else {
            console.log('no')
            filteredData = ordersData.filter(
                item => searchBy.searchList.reduce((total, search) => total || item[search].toLowerCase().includes(searchText.toLowerCase()), false)
            );
        }
        console.log(filteredData);
        setFilteredData(filteredData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, ordersData, searchBy])

    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filtereData.slice(indexOfFirstOrder, indexOfLastOrder);

    console.log(ordersData, filtereData);
    const orderColumns = userColumns({ setOrdersData })
    return (
        <div>
            <LayoutProvider>
                <AppRoot>
                    <AppMain>
                        <Sidebar />
                        <AppWrap>
                            <Header />
                            {/* <div className="nk-content"> */}

                            {/* </div> */}
                            <AppContent>
                                <Block.Head>
                                    <Block.HeadBetween>
                                        <Block.HeadContent>
                                            <Block.Title tag="h2">Orders List</Block.Title>
                                            <nav>
                                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                                    <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                                    <li className="breadcrumb-item"><Link to="">Orders Manage</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Orders</li>
                                                </ol>
                                            </nav>
                                        </Block.HeadContent>
                                    </Block.HeadBetween>
                                </Block.Head>
                                <div className="tabs-container" style={{ display: 'flex', alignItems: 'center' }}>
                                    <button
                                        className={activeTab === "CardsView" ? "active-tab" : "tabscards"}
                                        onClick={() => setActiveTab("CardsView")}
                                    >
                                        <BsCardHeading size='1.5em' />
                                    </button>
                                    <button
                                        className={activeTab === "ListView" ? "active-tab" : "tabscards"}
                                        onClick={() => setActiveTab("ListView")}
                                    >
                                        <BsTable size='1.5em' />
                                    </button>
                                    {activeTab === "CardsView" &&
                                        <>
                                            <input
                                                style={{ width: '200px', marginRight: '9px', height: '40px' }}
                                                className="form-control"
                                                placeholder={`Search by ${searchBy?.drill}`}
                                                type="text"
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                            <select name="searchBy" onChange={handleSearchBy} style={{ borderRadius: '5px', height: '40px' }}>
                                                <option value='User'>User</option>
                                                {/* <option value='Seller'>Seller</option> */}
                                                <option value='Restaurant'>Restaurant</option>
                                            </select>
                                        </>}
                                </div>

                                <>
                                    {activeTab === "CardsView" && (
                                        <div>
                                            <div className="card-container">
                                                {filtereData.map((order, index) => (
                                                    <div key={index} className="card">
                                                        <div className="user-info">
                                                            <div className="user-info-flex">
                                                                {/* <p>{order.order_id}</p> */}
                                                                {order?.OrderItems?.length > 0 ? (
                                                                    <div>


                                                                        {order?.OrderItems[0].Menu?.image_or_videos ? (
                                                                            <img src={order?.OrderItems[0]?.Menu?.image_or_videos} alt="User" />
                                                                        ) : (
                                                                            <img src="" />
                                                                            // <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROrvctvq_VpMFBmNcqG8YaEXWSIGUgiM5khw&usqp=CAU" alt="Default" />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <img src="" />
                                                                    // <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROrvctvq_VpMFBmNcqG8YaEXWSIGUgiM5khw&usqp=CAU" alt="Default" />
                                                                )}



                                                                <div className="restaurant-det">
                                                                    <div >
                                                                        <p className="user-name">{order?.User && order?.User?.full_name}</p>

                                                                    </div>
                                                                    <div>
                                                                        <p className="user-name">
                                                                            {order?.Useraddress && order?.Useraddress?.address_one}
                                                                            {order?.Useraddress && order?.Useraddress?.address_two && `, ${order?.Useraddress?.address_two}`}
                                                                        </p>
                                                                    </div>
                                                                    <Link to={`/restaurant-detail/${order.restaurant_id}`}>
                                                                        <div className="restaurant-adddress">
                                                                            <p className="rest-name"><IoRestaurant />{order?.Restaurant && order?.Restaurant?.name}</p>
                                                                            <p>{order?.Restaurant && order?.Restaurant.address}</p>
                                                                        </div>
                                                                    </Link>

                                                                </div>
                                                            </div>
                                                            <div className="delivered-msg">
                                                                <p className={order.order_status === 'Delivered' ? "delivered-message" : 'cancelled-message'}>{order && order.order_status}</p>

                                                            </div>
                                                        </div>
                                                        <div className="order-class">
                                                            <div>

                                                                {order?.OrderItems?.length > 0 ? (
                                                                    order?.OrderItems.map((item, itemIndex) => {

                                                                        return (
                                                                            <p key={itemIndex}>
                                                                                <span className={item?.Menu?.food_type === 'Non Veg' ? 'dot' : 'green-dot'} />
                                                                                {`${item?.quantity} * ${item?.Menu?.item_name}`}
                                                                            </p>

                                                                        )
                                                                    })
                                                                ) :
                                                                    (
                                                                        <p>No items selected</p>
                                                                    )}
                                                                <Link to={`/order-details/${order?.order_id}`}>
                                                                    <p className="view-menu">View order details<span> ► </span></p>
                                                                </Link>
                                                            </div>
                                                            <div className="order-details">
                                                                <span className="order-date">
                                                                    {order && new Date(order?.order_date).toLocaleString('en-US', { dateStyle: 'full', timeStyle: 'short' })}
                                                                </span>


                                                                <span className="price"> ₹{order && order?.final_amount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                )}

                                            </div>
                                            <div className="paginate-class">
                                                {/* <Pagination>
                                                        <Pagination.Prev
                                                            className="prev-class"
                                                            onClick={() => paginate(currentPage - 1)}
                                                            disabled={currentPage === 1}
                                                        />
                                                        {Array.from({
                                                            length: Math.ceil(
                                                                filtereData.length / ordersPerPage
                                                            ),
                                                        }).map((_, index) => (
                                                            <Pagination.Item
                                                                key={index}
                                                                active={index + 1 === currentPage}
                                                                onClick={() => paginate(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </Pagination.Item>
                                                        ))}
                                                        <Pagination.Next
                                                            className="next-class"
                                                            onClick={() => paginate(currentPage + 1)}
                                                            disabled={
                                                                currentPage ===
                                                                Math.ceil(filtereData.length / ordersPerPage)
                                                            }
                                                        />
                                                    </Pagination> */}

                                            </div>
                                        </div>
                                    )}
                                    {activeTab === "ListView" && (
                                        <div className="list-view-container">

                                            <Block>
                                                <Card>
                                                    <DataTable
                                                        tableClassName="data-table-head-light table-responsive"
                                                        data={ordersData}
                                                        columns={orderColumns}
                                                        searchList={['full_name', 'order_id', 'name']}
                                                        searchTerm='order id'
                                                        drill={'User'}
                                                        // actions={true}
                                                        page={page}
                                                        setpage={setpage}
                                                        rowsPerPages={rowsPerPage}
                                                        setRowsPerPage={setRowsPerPage}
                                                        fullData={UsersFullData}
                                                        search={order_id}
                                                        setSeatch={setOrder_id}
                                                        fetchData={fetchData}
                                                        orderDownload={true}
                                                    />
                                                </Card>
                                            </Block>
                                        </div>
                                    )}
                                </>
                            </AppContent>
                            <Footer />
                        </AppWrap>
                    </AppMain>
                </AppRoot>
            </LayoutProvider>
        </div>
    );
};

export default OrderList;

export const handleChangeOrderStatus = async (status, order_id, setOrdersData) => {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    const confirmed = window.confirm(`Are you sure you want to change this order Status to ${status} ?`);
    if (confirmed) {
        try {
            const payload = {
                order_status: status
            };
            let response;
            response = await axios.put(`${serverApiUrl}/api/Order/updateOrderBYAdmin/${order_id}`, payload);
            let apiUrl = `${serverApiUrl}/api/Admin/orders`;
            const response1 = await axios.get(apiUrl);
            setOrdersData(response1.data.rows);
            toast.success("Order status updated");
            // setCategoriesData(updatedResponse.data.rows);
        } catch (error) {
            console.error(`Error updated status with ID ${order_id}:`, error);
        }
    }
};