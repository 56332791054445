import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { MdVerified } from "react-icons/md";
import { handleChangeOrderStatus } from "../../pages/order-manage/OrderList";
// export const userColumns = [
  export const userColumns = ({ setOrdersData }) => {
    return [
    {
        name: "Order ID",
        selector: (row) => row?.order_id,
        cell: (row) => (
            <span>{row?.order_id}</span>
        ),
        sortable: true,
      },
      {
        name: "Order Date",
        selector: (row) => row?.order_date,
        cell: (row) => (
          <span>{new Date(row?.order_date).toDateString()}</span>
        ),
        sortable: true,
        minWidth: "150px",
      },
      {
        name: "Customer",
        selector: (row) => row?.Useraddress?.contact_name,
        cell: (row) => (
            <span>{row?.Useraddress?.contact_name}</span>
        ),
        sortable: true,
      },

      {
        name: "Customer Phone NO",
        selector: (row) => row?.Useraddress?.contact_phone,
        cell: (row) => (
            <span>{row?.Useraddress?.contact_phone}</span>
        ),
        sortable: true,
        minWidth: "200px",
      },
      {
        name: "Customer Address",
        // selector: (row) => row.Useraddress.contact_phone,
        cell: (row) => (
            <span>{row?.Useraddress?.address_one},{row?.Useraddress?.address_two},{row?.Useraddress?.address_two},{row?.Useraddress?.city},{row?.Useraddress?.state},{row?.Useraddress?.pincode}</span>
        ),
        sortable: true,
        minWidth: "200px",
      },
      
      {
        name: "Ordered Items",
        selector: (row) => row?.OrderItems,
        cell: (row) => {
          const menuStyle = {
            backgroundColor: "rgb(221 218 218)",
            borderColor: "#d4d4d4",
            padding: "5px 8px",
            borderRadius: "8px",
            marginBottom:'8px',
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize:"14px",
            display:"flex",
            justifyContent:"space-between",
            minWidth:"150px",
            maxWidth:"300px",
          };
      
          return (
            <div >
              <div style={{ maxHeight: "80px", minWidth:"130px", overflow: "auto" }}>
                {row?.OrderItems?.length > 0 &&
                  row?.OrderItems?.map((item, index) => (
                    <div style={menuStyle} key={index}>
                      <p className="strong-item-margin">
                      <strong>{item?.Menu?.item_name}</strong>
                      </p>
                      <div style={{ width: "18px", height: "20px", backgroundColor: "#2dc58c", borderRadius: "4px", textAlign: "center", color: "white" }}>
                  {item?.quantity}
                </div>
                    </div>
                  ))}
              </div>
            </div>
          );
        },
        sortable: true,
        minWidth: "200px",
      },

      {
        name: "Restaurant",
        selector: (row) => (row?.Restaurant ? row?.Restaurant?.name : ''),
        cell: (row) => (
            <span>{row?.Restaurant ? row?.Restaurant?.name : ''}</span>
        ),
        sortable: true,
        minWidth: "150px",
    },
    
      {
        name: "Total Amount",
        selector: (row) => row?.total_amount,
        cell: (row) => (
            <span>Rs.{row?.total_amount}</span>
        ),
       
        sortable: true,
      },
      {
        name: "Final Amount",
        selector: (row) => row?.final_amount,
        cell: (row) => (
            <span>Rs.{row?.final_amount}</span>
        ),
       
        sortable: true,
      },
      {
        name: "Admin Commission Amount",
        selector: (row) => row?.commission,
        cell: (row) => (
          <span>
          Rs.{row?.commission}
        </span>
        ),
        minWidth: "250px",
        sortable: true,
      },
      {
        name: "Admin GST Amount",
        minWidth: "200px",
        selector: (row) => row?.admin_commission_gst_amount,
        cell: (row) => (
          <span>
          Rs.{row?.admin_commission_gst_amount}
        </span>
        ),
       
        sortable: true,
      },
      {
        name: "Admin GOV TAX Amount",
        minWidth: "200px",
        selector: (row) => row?.admin_gov_tax_amount,
        cell: (row) => (
          <span>
          Rs.{row?.admin_gov_tax_amount}
        </span>
        ),
       
        sortable: true,
      },
      {
        name: "Delivery Charges",
        selector: (row) => row?.delivery_charge_to_order,
        cell: (row) => (
          <span>
          Rs.{row?.delivery_charge_to_order}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Total Admin Share",
        selector: (row) => row?.total_admin_commission,
        cell: (row) => (
          <span>
          Rs.{row?.total_admin_commission}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Payble Amount",
        selector: (row) => row?.remain_admin_payble_amount,
        cell: (row) => (
          <span>
          Rs.{row?.remain_admin_payble_amount}
        </span>
        ),
        minWidth: "150px",
        sortable: true,
      },
      {
        name: "Current Admin Commission",
        selector: (row) => row?.current_admin_commission,
        cell: (row) => (
          <span>
          {row?.current_admin_commission} %
        </span>
        ),
        minWidth: "250px",
        sortable: true,
      },
      {
        name: "Current Admin GST",
        selector: (row) => row?.current_admin_gst,
        cell: (row) => (
          <span>
          {row?.current_admin_gst}%
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Current Delivery Charge",
        selector: (row) => row?.current_delivery_charge,
        cell: (row) => (
          <span>
          Rs.{row?.current_delivery_charge}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Current DCP Amount",
        selector: (row) => row?.current_delivery_dcp,
        cell: (row) => (
          <span>
          Rs.{row?.current_delivery_dcp}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Current DCP Percentage",
        selector: (row) => row?.current_delivery_dcp_percentage,
        cell: (row) => (
          <span>
          {row?.current_delivery_dcp_percentage}%
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Payment Method",
        minWidth: "160px",
        selector: (row) => row.payment_method,
        cell: (row) => {
          const codstatusStyle = {
            marginBottom: "15px",
            color: "#00BFFF",
            backgroundColor: "#f8e9e6",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          const onlineStatusStyle = {
            marginBottom: "15px",
            color: "#2dc58c",
            backgroundColor: "#e6f8f1",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          return (
            <span style={row.payment_method	 === 'cod' ? codstatusStyle : onlineStatusStyle}>
              {row.payment_method	}
            </span>
          );
        },
        sortable: true,
      },
      {
        name: "Payment Status",
        minWidth: "160px",
        selector: (row) => row.payment_status,
        cell: (row) => {
          const pendingStyle = {
            marginBottom: "15px",
            color: "#c54b2d",
            backgroundColor: "#f8e9e6",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          const otherStatusStyle = {
            marginBottom: "15px",
            color: "#2dc58c",
            backgroundColor: "#e6f8f1",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          return (
            <span style={row.payment_status === 'pending' ? pendingStyle : otherStatusStyle}>
              {row.payment_status}
            </span>
          );
        },
        sortable: true,
      },
      // {
      //   name: "Change Order Status",
      //   minWidth: "160px",
      //   selector: (row) => row.order_status,
      //   cell: (row) => {
          
         
      //     return (
      //       <div>
      //         <select 
      //               name="change_order_status" 
      //               onChange={(event) => handleChangeOrderStatus(event.target.value,row.order_id,setOrdersData)} 
      //               style={{borderRadius:'5px', height:'30px',backgroundColor:'#cce5d5',border: 'none'}}
      //               value={row.order_status}
      //           >
      //               <option value="Pending">Pending</option>
      //               <option value="Accepted">Accepted</option>
      //               <option value="Processing">Processing</option>
      //               <option value="ReadyToDeliver">Ready To Deliver</option>
      //               <option value="DeliveryBoyPicked">Delivery Boy Picked</option>
      //               <option value="Delivered">Delivered</option>
      //               <option value="Cancelled">Cancelled</option>
      //           </select>
      //       </div>
      //       // <span style={row.order_status === 'Delivered' ? deliveredStyle : otherStatusStyle}>
      //       //   {row.order_status}
      //       // </span>
      //     );
      //   },
      //   sortable: true,
      // },

      {
        name: "Order Status",
        minWidth: "160px",
        selector: (row) => row.order_status,
        cell: (row) => {
          const deliveredStyle = {
            marginBottom: "15px",
            color: "#2dc58c",
            backgroundColor: "#e6f8f1 ",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          const otherStatusStyle = {
            marginBottom: "15px",
            color: "#c54b2d",
            backgroundColor: "#f8e9e6",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          return (
            <span style={row.order_status === 'Delivered' ? deliveredStyle : otherStatusStyle}>
              {row.order_status}
            </span>
          );
        },
        sortable: true,
      },

      {
        name: "Delivery Boy  Status",
        minWidth: "160px",
        selector: (row) => row.delivery_status_code,
        cell: (row) => {
          const deliveredStyle = {
            marginBottom: "15px",
            color: "#2dc58c",
            backgroundColor: "#e6f8f1 ",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          const otherStatusStyle = {
            marginBottom: "15px",
            color: "#c54b2d",
            backgroundColor: "#f8e9e6",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          return (
            <span style={row.delivery_status_code === 'DELIVERED' ? deliveredStyle : otherStatusStyle}>
              {row.delivery_status_code}
            </span>
          );
        },
        sortable: true,
      },
      {
        name: "Delivery Boy Name",
        selector: (row) => row.delivery_rider_name,
        cell: (row) => (
          <span>
          {row.delivery_rider_name}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Delivery Boy Number",
        selector: (row) => row?.delivery_rider_contact,
        cell: (row) => (
          <span>
          {row?.delivery_rider_contact}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      {
        name: "Delivery Task Id",
        selector: (row) => row?.delivery_taskId,
        cell: (row) => (
          <span>
          {row?.delivery_taskId}
        </span>
        ),
        minWidth: "200px",
        sortable: true,
      },
      
    ]
  };

