import { Row, Col, Card, Button, Dropdown, Table, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Colors } from '../../utilities/index';
import hexRGB from '../../utilities/hexRGB';
import { Link, useNavigate } from 'react-router-dom';
import { Icon, Select } from '../../components';
import { AiOutlineArrowLeft } from "react-icons/ai";

import { useParams } from 'react-router-dom';
function RestarentItemReview() {
  const { restaurant_id } = useParams();
  const serverApiUrl = process.env.REACT_APP_API_URL;
  const [surveryName, setSurveryName] = useState('');
  const [reviewslist, setReviewslist] = useState([]);
  const [resdata, setResdata] = useState({});
  const Navigate = useNavigate();

  const fetchchargeslist = async () => {
    const token = localStorage.getItem("token")

    try {
      const response = await fetch(
        `${serverApiUrl}/api//Review/restarentUserReviews/${restaurant_id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      const data = await response.json();
      console.log(data)
      setReviewslist(data.menuItemsWithReviews);
    } catch (error) {
      console.error("Error fetching category details:", error);
    }

  };
  const fetchRestarentDetails = async () => {
    try {
      const response = await axios.get(`${serverApiUrl}/api/Restaurant/${restaurant_id}`);
      const restarent_date = response.data;
      setResdata(restarent_date)
      console.log(restarent_date)

    } catch (error) {
      console.error('Error fetching Category details:', error);
    }
  };


  useEffect(() => {


    fetchRestarentDetails();
    fetchchargeslist();

  }, []);

  const reviewColumns = [
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      cell: (row) => <span>{row.item_name}</span>,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => row['Reviews.User.user_full_name'],
      cell: (row) => <span>{row['Reviews.User.user_full_name']}</span>,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row['Reviews.User.user_phone_number'],
      cell: (row) => <span>{row['Reviews.User.user_phone_number']}</span>,
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row['Reviews.rating'],
      cell: (row) => <span>{row['Reviews.rating']}</span>,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Comment",
      selector: (row) => row['Reviews.review_text'],
      cell: (row) => <span>{row['Reviews.review_text']}</span>,
      sortable: true,
      minWidth: "150px",
    },

    {
      name: "Created Date",
      selector: (row) => row['Reviews.review_createdAt'],
      cell: (row) => (
        <span>{new Date(row['Reviews.review_createdAt']).toDateString()}</span>

      ),
      sortable: true,
      minWidth: "200px",
    },
  ]

  return (
    <Layout title="Users List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">{resdata.name} Item's Review List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/Restaurants-manage/Restaurants-list">Restaurants</Link></li>
                <li className="breadcrumb-item">{restaurant_id}</li>
              </ol>
            </nav>

          </Block.HeadContent>


          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Link to={`/Restaurants-manage/Restaurants-list`}>
                  <Button className="d-none d-md-inline-flex" variant="primary">
                    <AiOutlineArrowLeft />
                    Back
                  </Button>
                </Link>
              </li>

            </ul>
          </Block.HeadContent>


        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {/* {reviewslist ? ( */}
          <DataTable tableClassName="data-table-head-light table-responsive" data={reviewslist} columns={reviewColumns} searchList={['item_name', 'Reviews.User.user_phone_number', 'Reviews.User.user_full_name']} searchTerm='' noSearch />
          {/* ):(<div>No Reviews available </div>)} */}
        </Card>
      </Block>


    </Layout>
  )
}

export default RestarentItemReview;
