import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Dropdown } from "react-bootstrap";
import { MdVerified } from "react-icons/md";
import { MediaGroup, Media, MediaText, Image, Icon, Select, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
// import  { userColumns } from '../../store/users/UsersData';
import axios from 'axios';
import { toast } from 'react-toastify';
import instance from '../../api';

function UserListPage() {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(true);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [UsersData, setUsersData] = useState([]);
    const [UsersFullData, setUsersFullData] = useState([]);
    const [page, setpage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    console.log(search);
    


    
    
    async function handleStatus(rowId, status) {
        const data = {

            "is_active": status
        }
        try {
            const response = await instance.put(`/User/update/${rowId}`, data)
            if (response.status === 201) {
                setRefresh(prev => !prev);
                console.log(refresh);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    async function handleVerified(rowId, verified) {
        const data = {
            "is_verified": verified,

        }
        try {
            const response = await instance.put(`/User/update/${rowId}`, data)
            if (response.status === 201) {
                setRefresh(prev => !prev);
                console.log(refresh);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const userColumns = [
        {
            name: "Users",
            grow: 2,
            selector: (row) => row.full_name,
            cell: (row) => (
                <MediaGroup>
                    <Media size="md" shape="circle" className="user-pro" variant={row.theme && row.theme}>
                        {row.profile_picture ? (
                            <Image src={`${serverApiUrl}/${row.profile_picture}`} staticImage />
                        ) : (
                            <span className="smaller fw-medium initials-container">
                                {row.full_name && toInitials(row.full_name)}
                            </span>
                        )}
                    </Media>


                    <MediaText>

                        <p style={{ margin: "0" }}>{row.full_name} {row.is_verified ? <MdVerified style={{ color: "green" }} /> : ""}</p>


                        <span className="small text" style={{ textWrap: "nowrap" }}>{row.email}</span>
                    </MediaText>
                </MediaGroup>
            ),
            sortable: true,
            minWidth: "300px",
        },
        {
            name: "Contact",
            selector: (row) => row.phone_number,
            cell: (row) => (
                <span>{row.phone_number}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "Total spent",
            hide: "lg",
            selector: (row) => row.total_spent,
            cell: (row) => (
                <span>{row.total_spent}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "DOB",
            selector: (row) => row.date_of_birth
            ,
            cell: (row) => (
                <span>{new Date(row.date_of_birth).toDateString()
                }</span>
            ),
            sortable: true,
            minWidth: "170px",

        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            cell: (row) => (
                <span>{row.gender}</span>
            ),
            sortable: true,
            hide: "lg",
        },
        // {
        //     name: "Total Spent",
        //     selector: (row) => row.total_spent,
        //     cell: (row) => (
        //         <span>{row.total_spent}</span>
        //     ),
        //     sortable: true,
        //     hide: "lg",
        // },

        {
            name: "joined date",
            selector: (row) => row.createdAt,
            cell: (row) => (
                <span style={{ textWrap: "nowrap" }}>{new Date(row.createdAt).toDateString()}</span>
            ),
            sortable: true,
            minWidth: "170px",
            hide: "lg",
        },
        {
            name: "status",
            selector: (row) => row.is_active,
            cell: (row) => (
                // <span className={`badge text-bg-${
                //     row.is_active === "Active" ? "success-soft" 
                //     : row.is_active === "Pending" ? "warning-soft" 
                //     : row.is_active === "Inactive" ? "secondary-soft" 
                //     : "primary-soft"}`
                // }>
                // {row.is_active ? row.is_active : 'General'}
                // </span>
                <span className={`badge text-bg-${row.is_active ? "success-soft" : "secondary-soft"
                    // : row.is_active === "Pending" ? "warning-soft" 
                    // : row.is_active === "Inactive" ? "secondary-soft" 
                    // : "primary-soft"
                    }`
                }>
                    {row.is_active ? "Active" : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Address",
            selector: (row) => row.Useraddresses,
            cell: (row) => (

                <span>
                    {row.Useraddresses && row.Useraddresses.length > 0 ? (
                        <span>{row.Useraddresses[0].address_one},{row.Useraddresses[0].address_two},{row.Useraddresses[0].landmark},{row.Useraddresses[0].city},{row.Useraddresses[0].state},{row.Useraddresses[0].pincode},{row.Useraddresses[0].country}</span> // Replace 'address_field' with the actual field you want to display
                    ) : (
                        <span>No address available</span>
                    )}
                </span>
            ),
            sortable: true,
            minWidth: "300px",
        },
        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    {/* <LinkListItem to={`/user-manage/user-edit/${row.user_id}`}>
                                      <Icon name="edit"></Icon><span>Edit</span>
                                  </LinkListItem> */}
                                    <Dropdown.Item onClick={() => { handleStatus(row.user_id, !row.is_active) }} style={{ border: 'none', backgroundColor: 'white' }}>
                                        <Icon name="active"></Icon><span>{row.is_active ? "Mark as Inactive" : "Mark as Active"}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => { handleVerified(row.user_id, !row.is_verified) }} style={{ border: 'none', backgroundColor: 'white' }}>
                                        <Icon name="active"></Icon><span>{row.is_verified ? "Mark as not verified" : "Mark as verified"}</span>
                                    </Dropdown.Item>

                                    {/* <LinkListItem to={`/user-manage/user-profile/${row.user_id}`}>
                                      <Icon name="block"></Icon><span>Block</span>
                                  </LinkListItem> */}
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,
            hide: "md",
        },

    ];

    async function getUserData(page,rowsPerPage,search) {
        try {
            const response = await instance.get(`/Admin/users?limit=${rowsPerPage}&page=${page}&search=${search}`)
            if (response.status === 200) {
                setUsersData(response.data.rows);
                setUsersFullData(response.data);
                // console.log(response.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserData(page,rowsPerPage,search);
    }, [refresh,page,rowsPerPage,search])
    console.log(UsersFullData);

    const today = new Date();
    const to_date = today.toISOString().split('T')[0];
    const sixDaysBeforeToday = new Date(today.setDate(today.getDate() - 6));
    const from_date = sixDaysBeforeToday.toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState(''); // Initialize with an empty string or a specific date string
    const [toDate, setToDate] = useState(''); // Initialize with an empty string or a specific date string
    const [gender, setGender] = useState(''); // Initialize with an empty string or a specific date string

    // Handle date change
    const handleFromDateChange = (event) => {
        const selectedFromDate = event.target.value;
        setFromDate(event.target.value);
        console.log(selectedFromDate)
        console.log(toDate)
        if (selectedFromDate && toDate && toDate < selectedFromDate) {
            setToDate('');
            toast.error('To date is higher than From date');
        }
    };
    const handleToDateChange = (event) => {
        setToDate(event.target.value);
        const selectedToDate = event.target.value;
        console.log(selectedToDate)
        console.log(fromDate)
        if (fromDate && selectedToDate && selectedToDate < fromDate) {
            setToDate('');
            toast.error('To date is higher than From date');
        }
    };
    const get_users_by_dates_clear = async () => {
        setFromDate('');
        setToDate('');
        setGender('')
        try {

            const uesrresponse = await instance.get(`/Admin/users`);
            if (uesrresponse.status === 200) {
                setUsersData(uesrresponse.data.rows);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const get_users_by_dates = async () => {


        try {

            const uesrresponse = await instance.get(`/Admin/users?from_date=${fromDate}&to_date=${toDate}&gender=${gender}`);
            if (uesrresponse.status === 200) {
                setUsersData(uesrresponse.data.rows);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }


    }

    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Users List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/user-manage/user-list">User Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Users</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    {/* <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                <Icon name="plus"/>
                                <span>Add</span>
                            </Button>
                        </li>
                        <li>
                            <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                <Icon name="plus"/>
                                <span>Add User</span>
                            </Button>
                        </li>
                    </ul>
                </Block.HeadContent> */}
                </Block.HeadBetween>
            </Block.Head>
            <Col xxl="12">
                <Row className="g-0 col-sep col-sep-md">
                    <Col md="3">
                        <Form.Control
                            id="couponname"
                            type="date"
                            placeholder="Name"
                            required
                            value={fromDate}
                            onChange={(e) => handleFromDateChange(e)}

                        />

                    </Col>
                    <Col md="3">
                        <Form.Control
                            id="toDate"
                            type="date"
                            placeholder="Name"
                            required
                            value={toDate}
                            onChange={(e) => handleToDateChange(e)}

                        />

                    </Col>
                    <Col md="2">
                        <Select
                            removeItemButton
                            value={gender}
                            onChange={(e) => {
                                setGender(e.target.value)
                            }}
                        >
                            <option value="" disabled selected hidden>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </Select>

                    </Col>
                    <Col md="2">
                        <Button variant="primary" onClick={get_users_by_dates}>Submit</Button>
                    </Col>
                    <Col md="2">
                        <Button variant="warning" onClick={get_users_by_dates_clear}>Clear</Button>
                    </Col>
                </Row>
            </Col>
            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" userDownload={true} setSeatch={setSearch} page={page} setpage={setpage} rowsPerPages={rowsPerPage} setRowsPerPage={setRowsPerPage} fullData={UsersFullData} data={UsersData} columns={userColumns} searchList={['full_name', 'email', 'phone_number']} searchTerm='Name | Email | Phone' />
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="firstname">First Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="firstname" type="text" placeholder="First name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="lastname">Last Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="lastname" type="text" placeholder="Last name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">Email Address</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="email" type="text" placeholder="Email address" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label>Status</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a status</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Active</option>
                                            <option value="3">Inactive</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group className="form-group">
                                    <Form.Label>Role</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a role</option>
                                            <option value="1">Administrator</option>
                                            <option value="2">Developer</option>
                                            <option value="3">Analyst</option>
                                            <option value="4">Support</option>
                                            <option value="5">Trial</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={handleCloseModal}>Add User</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export default UserListPage;