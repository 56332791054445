import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Icon, Select } from '../../components';
import { userColumns } from '../../store/categories/CategoriesData';

import axios from 'axios';
import instance from '../../api';
function CategoriesList() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { category_id } = useParams();
    console.log('kjkj', category_id)
    const [showModal, setShowModal] = useState(false);

    const [categoriesData, setCategoriesData] = useState([]);
    const [categoryImage, setCategoryImage] = useState(null);



    const [categoryName, setCategoryName] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const Navigate = useNavigate();
    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        Navigate('/categories-manage/categories-list');
        setCategoryName('');
        setCategoryImage(null);
        setEditingCategory(null);
        setFieldErrors({
            categoryName: false,
            categoryImage: false,

        });
    };
    const [fieldErrors, setFieldErrors] = useState({
        categoryName: false,
        categoryImage: false,

    });
    const validateForm = () => {
        const errors = {};

        if (!categoryName) {
            errors.categoryName = true;
        }
        if (!categoryImage) {
            errors.categoryImage = true;
        }


        setFieldErrors(errors);

        if (Object.values(errors).some((error) => error)) {

            return false;
        }

        return true;
    };

    const handleFileInputClick = () => {
        document.getElementById('category-image-upload-input').click();
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setCategoryImage(file);
    };

    const fetchCategoryDetails = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Category/${category_id}`);
            const category = response.data;
            console.log(category)
            setCategoryName(category.name || '');
            setCategoryImage(category.image || '')

            setEditingCategory(category);

            handleShowModal();
        } catch (error) {
            console.error('Error fetching Category details:', error);
        }
    };
    console.log(editingCategory)
    const postCategoryData = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            let payload;

            if (categoryImage instanceof File) {
                const formData = new FormData();
                formData.append('files', categoryImage);

                const uploadResponse = await axios.post(`${apiUrl}/api/Common/Upload`, formData);

                if (uploadResponse.data.message === 'uploaded file.') {
                    payload = {
                        name: categoryName,
                        image: uploadResponse.data.file_paths[0],
                    };
                }
            } else {

                payload = {
                    name: categoryName,
                    image: editingCategory ? editingCategory.image : null,
                };
            }

            let categoryResponse;
            if (editingCategory) {
                categoryResponse = await axios.put(`${apiUrl}/api/Category/update/${editingCategory.category_id}`, payload);
            } else {
                categoryResponse = await instance.post(`/Category/create`, payload);
            }

            console.log("Category response:", categoryResponse.data);
            handleCloseModal();

            const updatedResponse = await axios.get(`${apiUrl}/api/Category`);
            setCategoriesData(updatedResponse.data.rows);

            handleCloseModal();
            setCategoryName('');
            setCategoryImage(null);
        } catch (error) {
            console.error("Error uploading file or creating category:", error);
        }
    };





    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/Category`);
                setCategoriesData(response.data.rows);
                console.log(response.data.rows)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        if (category_id) {
            fetchCategoryDetails();
        }
    }, [category_id]);



    const categoryColumns = userColumns({ setCategoriesData })

    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Categories List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Categories Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Categories</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Category</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={categoriesData} columns={categoryColumns} searchList={['name']} searchTerm='Category Name' />
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCategory ? 'Edit Category' : 'Add Category'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor=" categoryName">Category Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id=" categoryName"
                                            type="text"
                                            placeholder="Name"
                                            value={categoryName}
                                            required
                                            onChange={(e) => setCategoryName(e.target.value)}
                                            className={fieldErrors.categoryName ? 'is-invalid' : ''}
                                        />
                                    </div>
                                    {fieldErrors.categoryName && (
                                        <div className="invalid-feedback">Please enter a category name.</div>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="categoryImage">Category Image</Form.Label>
                                    <div className="form-control-wrap">
                                        <input
                                            id="category-image-upload-input"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageUpload}
                                            accept='image/jpg,image/jpeg,image/png'
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={handleFileInputClick}
                                            disabled={editingCategory !== null && categoryImage === null}
                                            className={fieldErrors.categoryImage ? 'is-invalid' : ''}
                                        >
                                            Upload Image
                                        </Button>
                                        {fieldErrors.categoryImage && (
                                            <div className="invalid-feedback">Please upload a category image.</div>
                                        )}
                                        {categoryImage && (
                                            <img
                                                src={categoryImage instanceof File ? URL.createObjectURL(categoryImage) : `${apiUrl}/${categoryImage}`}
                                                style={{ maxWidth: '100%', maxHeight: '80px', marginTop: '10px' }}
                                            />
                                        )}

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={postCategoryData}>
                                            {editingCategory ? 'Update Category' : 'Add Category'}
                                        </Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export const handleDeleteCategory = async (category_id, setCategoriesData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const confirmed = window.confirm('Are you sure you want to delete this category?');
    if (confirmed) {
        try {
            await axios.delete(`${apiUrl}/api/Category/delete/${category_id}`);
            console.log(`Category with ID ${category_id} deleted successfully.`);
            const updatedResponse = await axios.get(`${apiUrl}/api/Category`);
            setCategoriesData(updatedResponse.data.rows);
        } catch (error) {
            console.error(`Error deleting category with ID ${category_id}:`, error);


            console.error(error.response);
            console.error(error.message);
        }
    }
};
export const handleToggleEnable = async (category_id, currentStatus, setCategoriesData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const confirmed = window.confirm(`Are you sure you want to ${currentStatus ? 'disable' : 'enable'} this category?`);

    if (confirmed) {
        try {
            const updatedStatus = !currentStatus;


            await axios.put(`${apiUrl}/api/Category/update/${category_id}`, {
                is_enable: updatedStatus,
            });

            console.log(`Category with ID ${category_id} ${updatedStatus ? 'disabled' : 'enabled'} successfully.`);


            const updatedResponse = await axios.get(`${apiUrl}/api/Category`);
            setCategoriesData(updatedResponse.data.rows);
        } catch (error) {
            console.error(`Error toggling enable status for category with ID ${category_id}:`, error);

        }
    }
};


export default CategoriesList;