import { useState,useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Dropdown } from "react-bootstrap";
import { MdVerified } from "react-icons/md";
import { MediaGroup, Media, MediaText, Image, Icon, Select, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import * as Yup from 'yup';
import axios from 'axios';
function DeliveryBoysList() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);
  const [deliveryBoyData,setDeliveryBoyData] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [refresh, setRefresh] = useState(true);

  async function handleStatus(rowId, status){
    const data  = {
        "is_active": status
      }
    try{
        const response = await axios.put(`${apiUrl}/api/DeliveryBoy/update/${rowId}`, data,{
            headers:{
                "Accept": "application/json"
            }
        })
        if(response.status === 201){
            setRefresh(prev => !prev);
            // console.log(refresh);
        }
    }
    catch (error){
        console.log(error);
    }
  }

    const userColumns = [
        {
            name: "Name",
            grow: 2,
            selector: (row) => row.name,
            cell: (row) => (
                <MediaGroup>
                    <MediaText>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <span>
                        {row.name}
                    </span>
                </div>
                    </MediaText>
                </MediaGroup>
            ),
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            cell: (row) => (
                <span>{row.email}</span>
            ),
            sortable: true,
        },
        {
            name: "Contact",
            selector: (row) => row.phone,
            cell: (row) => (
                <span>{row.phone}</span>
            ),
            sortable: true,
        },
        {
            name: "Joined Date",
            selector: (row) => row.createdAt,
            cell: (row) => (
                <span>{new Date(row.createdAt).toDateString()}</span>

            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row.is_active,
            cell: (row) => (
                <span className={`badge text-bg-${row.is_active ? "success-soft" : "secondary-soft"}`}>
                    {row.is_active ? "Active" : "Inactive"}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Availability",
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge text-bg-${row.status === 'online' ? "success-soft" : "secondary-soft"}`}>
                    {row.status === 'online' ? "Online" : "Offline"}
                </span>
            ),
            sortable: true,
        },
        {
            name: "action",
            cell: (row) => (

                <div className="text-end w-100">

                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">

                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    <Dropdown.Item onClick={() => {handleStatus(row.delivery_boy_id, !row.is_active)}} style={{border:'none', backgroundColor:'white'}}>
                                      <Icon name="active"></Icon><span>{!row.is_active ? "Mark as Active" : "Mark as Inactive"}</span>
                                    </Dropdown.Item>
                                </LinkList>

                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,
            hide: "md",
        },

    ];
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/DeliveryBoy`,);
        setDeliveryBoyData(response.data.rows);
        // console.log(response.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [refresh]); 

//   console.log({deliveryBoyData})

    const {handleSubmit, handleChange, handleBlur, resetForm, values, errors, touched} = useFormik({
        initialValues: {
        name: '',
        phone: '',
        email: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            phone: Yup.number().typeError().positive().integer().min(10).required(),
        }),
        onSubmit: values => {
            // console.log(values);
            resetForm();
            handleCloseModal();
            submitData({
                name:values.name,
                phone:values.phone,
                email:values.email
            });
        }
    });

    async function submitData(data){
        try {
            const response = await axios.post(`${apiUrl}/api/DeliveryBoy/create`,data,);
            // console.log(response)
          } catch (error) {
            console.error("Error submiting data:", error);
          }
        setRefresh(prev => !prev);
    }
  
  return (
    <Layout title="Users List" content="container">
        <Block.Head>
            <Block.HeadBetween>
                <Block.HeadContent>
                    <Block.Title tag="h2">Seller List</Block.Title>
                    <nav>
                        <ol className="breadcrumb breadcrumb-arrow mb-0">
                          <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                          <li className="breadcrumb-item"><Link to="/seller-manage/seller-list">seller Manage</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Users</li>
                        </ol>
                    </nav>
                </Block.HeadContent>
                <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                <Icon name="plus"/>
                                <span>Add Delivery Boy</span>
                            </Button>
                        </li>
                        <li>
                            <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                <Icon name="plus"/>
                                <span>Add Delivery Boy</span>
                            </Button>
                        </li>
                    </ul>
                </Block.HeadContent>
            </Block.HeadBetween>
        </Block.Head>

      <Block>
        <Card>
          <DataTable tableClassName="data-table-head-light table-responsive" data={deliveryBoyData} columns={userColumns} searchList={['name']} searchTerm = 'Name'/>
        </Card>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Delivery Boy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
              <Row className="g-3">
                  <Col lg="6">
                      <Form.Group className="form-group">
                          <Form.Label htmlFor="firstname">Name *</Form.Label>
                          {/* {errors.name && touched.name ? (<sub style={{fontSize:'xxx-small', color:'red'}}>{errors.name}</sub>) : null} */}
                          <div className="form-control-wrap">
                              <Form.Control style={{borderColor: errors.name && touched.name ? 'red' : ''}} id="firstname" name='name'  type="text" placeholder="Name" onChange={handleChange} onBlur={handleBlur} value={values.name}/>
                          </div>
                      </Form.Group>
                  </Col>
                  <Col lg="6">
                      <Form.Group className="form-group">
                          <Form.Label htmlFor="lastname">Phone *</Form.Label>
                          <div className="form-control-wrap">
                              <Form.Control style={{borderColor: errors.phone && touched.phone ? 'red' : ''}} id="lastname" name='phone' type="text" placeholder="Phone" onChange={handleChange} onBlur={handleBlur} value={values.phone}/>
                          </div>
                      </Form.Group>
                  </Col>
                  <Col lg="6">
                      <Form.Group className="form-group">
                          <Form.Label htmlFor="email">Email Address *</Form.Label>
                          <div className="form-control-wrap">
                              <Form.Control style={{borderColor: errors.email && touched.email ? 'red' : ''}} id="email" name='email' type="text" placeholder="Email address" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
                          </div>
                      </Form.Group>
                  </Col>
                  <Col lg="12">
                      <div className="d-flex gap g-2">
                          <div className="gap-col">
                              <Button variant="primary" type='submit'>Add Delivery Boy</Button>
                          </div>
                          <div className="gap-col">
                              <button type='button' onClick={resetForm} className="border-0 btn">Cancel</button>
                          </div>
                      </div>
                  </Col>
              </Row>
          </Form>
        </Modal.Body>
      </Modal>

    </Layout>
  )
}

export default DeliveryBoysList;