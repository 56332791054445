import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Icon, Select } from '../../components';
import axios from 'axios';
import { IoRemoveCircle } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { MdVerified } from "react-icons/md";
import { MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";

function RestaurantsList() {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    const { restaurant_id } = useParams();
    console.log('restaurant_id', restaurant_id)
    const [showModal, setShowModal] = useState(false);
    const [restaurantsData, setRestaurantsData] = useState([]);
   
    const [adminCommission, setAdminCommission] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [isGSTExist, setIsGSTExist] = useState('');
    const [tds, setTds] = useState('');
    const [userTax, setUserTax] = useState('');
    const [adminGst, setAdminGst] = useState('');

    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState(['']);
    const Navigate = useNavigate();
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
      setShowModal(false);
      Navigate('/restaurants-manage/restaurants-list');
      setAdminCommission('');
      setGstNumber('');
      setIsGSTExist('');
      setTds('');
      setUserTax('');
      setAdminGst('');
     
  };
    useEffect(() => {
      if (restaurant_id) {
          fetchRestarentDetails();
      }
    }, [restaurant_id]);
    const handleRadioChange = (e) => {
      setIsGSTExist(e.target.value);
    };
    const fetchRestarentDetails = async () => {
      try {
          const response = await axios.get(`${serverApiUrl}/api/Restaurant/${restaurant_id}`);
          const restarent_date = response.data;
          console.log(restarent_date)
          setAdminCommission(restarent_date.admin_commission);
          setGstNumber(restarent_date.gst_number);
          if(restarent_date.is_GSTExist){
            setIsGSTExist(1);
          }else{
            setIsGSTExist(0);
          }
          console.log(isGSTExist)
          setTds(restarent_date.tds);
          setUserTax(restarent_date.basic_gst);
          setAdminGst(restarent_date.admin_gst);

          handleShowModal();
      } catch (error) {
          console.error('Error fetching Category details:', error);
      }
    };

    const postCategoryData = async () => {
          
          try {
              let payload;

              payload = {
                restaurant_id: restaurant_id,
                adminCommission: adminCommission,
                gstNumber:gstNumber,
                isGSTExist:isGSTExist,
                tds:tds,
                userTax:userTax,
                adminGst:adminGst
              };
              console.log(payload)
              let restarentResponse;
              if (restaurant_id) {
                restarentResponse = await axios.put(`${serverApiUrl}/api/Restaurant/updateAdmin/${restaurant_id}`, payload);
                console.log(restarentResponse)
              } else {
                
              }
      
              handleCloseModal();
      
          } catch (error) {
              console.error("Error uploading file or creating category:", error);
          }
      };

    const formatTimeRange = (openTime, closeTime) => {
        try {
          const formattedOpenTime = formatTime(openTime);
          const formattedCloseTime = formatTime(closeTime);
          return `${formattedOpenTime} to ${formattedCloseTime}`;
        } catch (error) {
          console.error("Error formatting time range:", error.message);
          return "Invalid Time Range";
        }
      };
      
      const formatTime = (timeString) => {
        try {
          if (!timeString || typeof timeString !== "string") {
            throw new Error("Invalid or empty time string");
          }
      
          const [hours, minutes, seconds] = timeString.split(":").map(Number);
      
          if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            throw new Error("Invalid time components");
          }
      
          const period = hours >= 12 ? "PM" : "AM";
          const formattedHours = hours % 12 || 12;
          const formattedTime = `${formattedHours}:${String(minutes).padStart(
            2,
            "0"
          )} ${period}`;
      
          return formattedTime;
        } catch (error) {
          console.error("Error formatting time:", error.message);
          return "Invalid Time";
        }
      };
      const [refresh, setRefresh] = useState(true);

   async function handleverified(rowId,verified) {
  const data = {
      "is_verified": verified,
  }
  try {
      const response = await axios.put(`${serverApiUrl}/api/Restaurant/update/${rowId}`, data, {
          headers: {
              "Accept": "application/json"
          }
      })
      if (response.status === 201) {
        setRestaurantsData(prevData => 
            prevData.map(restaurant => 
              restaurant.restaurant_id === rowId ? { ...restaurant, is_verified: verified } : restaurant
            )
          );
      }
  }
  catch (error) {
      console.log(error);
  }
}

const userColumns = [
    {
      name: "Restaurant ID",
      selector: (row) => row.restaurant_id,
      cell: (row) => <span>{row.restaurant_id}</span>,
      sortable: true,
    },
    {
      name: " Restaurant Name",
      selector: (row) => row.name,
      cell: (row) => (
        <Tooltip title={`View  this restaurant ${row.name}`}>
          <Link to={`/restaurant-detail/${row.restaurant_id}`}>
            <MediaGroup>
              <Media
                className="rest-image-last"
                size="md"
                shape="circle"
                variant={row.theme && row.theme}
              >
                {row.RestaurantFiles &&
                row.RestaurantFiles[0] &&
                row.RestaurantFiles[0].image ? (
                  <Image
                    src={`${serverApiUrl}/${row.RestaurantFiles[0].image}`}
                    staticImage
                  />
                ) : (
                  ""
                )}
              </Media>
              <MediaText>
                <span className="small text" style={{ whiteSpace: "nowrap" }}>
                <p style={{ margin: "0" }}>{row.name} {row.is_verified ? <MdVerified style={{ color: "green" }} /> : ""}</p>
                  
                </span>
              </MediaText>
            </MediaGroup>
          </Link>
        </Tooltip>
      ),
      sortable: true,
      minWidth: "300px",
    },
    {
        name: "Documents",
        selector: (row) => row.restaurant_doc,
        cell: (row) => (
            <div>
            {row.restaurant_doc ? (
                row.restaurant_doc.endsWith(".pdf") ? (
                    <a href={`${serverApiUrl}/${row.restaurant_doc.trim()}`} target="_blank" rel="noopener noreferrer">
                        View Document
                    </a>
                ) : (
                    <a href={`${serverApiUrl}/${row.restaurant_doc.trim()}`} target="_blank" rel="noopener noreferrer">
                        <img
                            src={`${serverApiUrl}/${row.restaurant_doc.trim()}`}
                            alt="Document"
                            style={{ marginRight: "8px", maxWidth: "40px", maxHeight: "40px", cursor: "pointer" }}
                        />
                    </a>
                )
            ) : (
                <span className="badge text-bg-secondary-soft" style={{ marginRight: "8px" }}>Not uploaded</span>
            )}
        </div>
          
        ),
        sortable: true,
      }
      ,      
    {
        name: "Commission",
        selector: (row) => row.admin_commission,
        cell: (row) => (
          <span className={row.admin_commission ? '' : 'notset-com'}>
         {row.admin_commission ? `${row.admin_commission}%` : 'Not set'}
          </span>
        ),
        sortable: true,
        minWidth: "250px",
      },
      
      
    {
      name: "Owner Details",
      selector: (row) => row.owner_email,
      cell: (row) => {
        const imageUrl =
          row.RestaurantFiles &&
          row.RestaurantFiles[0] &&
          row.RestaurantFiles[0].image;
  
        return (
          <MediaGroup>
            {/* <Media size="md" shape="circle" variant={row.theme && row.theme}>
                {imageUrl ? (
                  <Image src={`${serverApiUrl}/${imageUrl}`} staticImage />
                ) : (
                  ''
                )}
              </Media> */}
            <MediaText>
              <span className="smaller fw-medium">{row.owner_email}</span>
              <span className="small text" style={{ textWrap: "nowrap" }}>
                {row.owner_number}
              </span>
            </MediaText>
          </MediaGroup>
        );
      },
      sortable: true,
      minWidth: "300px",
    },
  
    {
      name: "Address",
      selector: (row) => `${row.address},${row.city}`,
      cell: (row) => (
        <span>
          {row.address},{row.city}
        </span>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Timings",
      selector: (row) =>
        `${formatTime(row.timings_open)} to ${formatTime(row.timings_close)}`,
      cell: (row) => (
        <span>{formatTimeRange(row.timings_open, row.timings_close)}</span>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Item Reviews",
      // selector: (row) =>,
      cell: (row) => (
        <Link to={`/restaurants-manage/item_reviews/${row.restaurant_id}`}>
           <span>{row.rating}</span> View
        </Link>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Cuisine Type",
      selector: (row) => row.cuisine_type,
      cell: (row) => <span>{row.cuisine_type}</span>,
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v"></Icon>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
              <LinkListItem className="link-list-hover-bg-primary link-list-md" to={`/restaurants-manage/restaurants-list/${row.restaurant_id}`}>
                                        <Icon name="edit"></Icon><span> &nbsp;Edit Restaurant</span>
                                    </LinkListItem>
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Dropdown.Item
                    onClick={() => {
                      handleverified(
                        row.restaurant_id,
                        !row.is_verified
                      );
                    }}
                    style={{ border: "none", backgroundColor: "white" }}
                  >
                    <Icon name="active"></Icon>
                    <span>
                      {row.is_verified
                        ? "Mark as not verified"
                        : "Mark as verified"}
                    </span>
                  </Dropdown.Item>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
      hide: "md",
    },
    // {
    //     name: "Status",
    //     selector: (row) => row.survey.status,
    //     cell: (row) => {
    //         const otherStatusStyle = {
    //             marginBottom: "15px",
    //             color: "#c54b2d",
    //             backgroundColor: "#f8e9e6",
    //             borderColor: "transparent",
    //             padding: "5px",
    //             borderRadius: "6px",
    //         };
  
    //         const Inactive = {
    //             marginBottom: "15px",
    //             color: "#2dc58c",
    //             backgroundColor: "#e6f8f1",
    //             borderColor: "transparent",
    //             padding: "5px",
    //             borderRadius: "6px",
    //         };
  
    //         return (
    //             <span style={row.survey.status === '"Active"' ? otherStatusStyle : Inactive}>
    //                 {row.survey.status}
    //             </span>
    //         );
    //     },
    //     sortable: true,
  
    // },
  ];
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${serverApiUrl}/api/Restaurant`);
            setRestaurantsData(response.data.rows);
            console.log(response.data.rows)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, []);
const adminCommissionChange = (e,type) => {
  const value = e.target.value;
  // Regular expression to allow only numbers or numbers with one decimal place
  // const reg = /^\d*\.?\d{0,1}$/;
 
    const reg = /^\d{1,2}(\.\d{0,2})?$/;
    // Check if the value is empty or matches the pattern and is not starting with zero unless it's precisely '0' or '0.x'
      if (value === '' || (reg.test(value) && (!value.startsWith('0') || value.match(/^0(\.\d{0,1})?$/)))) {
        if(type==='ac'){
          setAdminCommission(value);
        }else if(type==='admingst'){
          setAdminGst(value)
        } else if(type==='usergst'){
          setUserTax(value)
        } else if(type==='tds'){
          setTds(value)
        }
        else{

        }

      
  }
 

 
};
    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Restaurants List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Restaurants Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Restaurants</li>
                            </ol>
                        </nav>
                        
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            
                           
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={restaurantsData} columns={userColumns} searchList={['name']} searchTerm = 'Name'/>
                </Card>
            </Block>
           
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Restaurant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="admin_commission">Admin Commission</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="admin_commission"
                                            type="text"
                                            placeholder="Enter number (e.g., 5 or 5.4)"
                                            value={adminCommission}
                                            required
                                            onChange={(e) => adminCommissionChange(e,'ac')}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="admin_gst">Admin GST</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="admin_gst"
                                            type="text"
                                            placeholder="Enter number (e.g., 5 or 5.4)"
                                            pattern="^\d+(\.\d)?$"
                                            value={adminGst}
                                            required
                                            onChange={(e) => adminCommissionChange(e,'admingst')}
                                            // onChange={(e) => setAdminGst(e.target.value)}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="form-group">
                                    <Form.Label>Is GST Exists:</Form.Label>
                                    <div className="form-control-wrap" key={`inline-radio`} style={{ padding: '5px' }}>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="color"
                                            type="radio"
                                            id={`inline-radio-1`}
                                            value="1"
                                            checked={isGSTExist == '1'}
                                            onChange={handleRadioChange}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="color"
                                            type="radio"
                                            id={`inline-radio-2`}
                                            value="0"
                                            checked={isGSTExist == '0'}
                                            onChange={handleRadioChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="gstNumber">GST Number</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="gstNumber"
                                            type="text"
                                            placeholder="Enter GST number"
                                            pattern="^\d+(\.\d)?$"
                                            value={gstNumber}
                                            required
                                            onChange={(e) => setGstNumber(e.target.value)}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="userTax">User Tax</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="usertax"
                                            type="text"
                                            placeholder="Enter TAX (e.g., 5 or 5.4)"
                                            pattern="^\d+(\.\d)?$"
                                            value={userTax}
                                            required
                                            onChange={(e) => adminCommissionChange(e,'usergst')}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="tds">TDS</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="tds"
                                            type="text"
                                            placeholder="Enter TDS (e.g., 5 or 5.4)"
                                            pattern="^\d+(\.\d)?$"
                                            value={tds}
                                            required
                                            onChange={(e) => adminCommissionChange(e,'tds')}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                       <Button variant="primary" onClick={postCategoryData}>
                                          Update Restaurant
                                        </Button> 
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
          
        </Layout>
    )
}

export default RestaurantsList;