import React from "react";
import { Routes, Route } from "react-router-dom";

import { ScrollToTop } from "../components";

// user manage
import UserList from "../pages/user-manage/UserList";
import UserCards from "../pages/user-manage/UserCards";
import UserProfile from "../pages/user-manage/UserProfile";
import UserEdit from "../pages/user-manage/UserEdit";

// delivery boys
import DeliveryBoysList from "../pages/delivery-boys/deliveryBoysList";

//seller manage
import SellerList from '../pages/seller-manage/SellerList';
//seller manage
import OrderList from '../pages/order-manage/OrderList';
// admin
import Profile from "../pages/admin/Profile";
import ProfileSettings from "../pages/admin/ProfileSettings";

// ecommerce
import Products from "../pages/ecommerce/Products";
import AddProduct from "../pages/ecommerce/AddProduct";
import EditProduct from "../pages/ecommerce/EditProduct";
import OrderDetails from "../pages/ecommerce/Orders/OrederDetails";

// auths pages
import AuthRegister from "../pages/auths/AuthRegister";
import AuthLogin from "../pages/auths/AuthLogin";
import AuthReset from "../pages/auths/AuthReset";

import NotFound from "../pages/error/NotFound";
import HomeEcommerce from "../pages/HomeEcommerce";
import MessageToUser from "../pages/MessageToUser";
import AutenticationCheck from "../pages/AutenticationCheck";
import TransactionList from "../pages/transaction-manage/TransactionList";
import CouponsList from "../pages/coupons/CouponsList";
import SurveyList from "../pages/survey-manage/SurveyList";
import RestaurantDetails from "../pages/RestaurantDetails";
import CategoriesList from "../pages/categories-manage/CategoriesList";
import RestaurantsList from "../pages/restaurants/RestaurantsList";
import CuisinesList from "../pages/cuisines/CuisinesList";
import PriceMessages from "../pages/PriceMessges";
import DeliveryCharges from "../pages/delivery_charges/DeliveryCharges";
import Dcp from "../pages/dcp/dcp";
import UserSurveyList from "../pages/survey-manage/UserSurveyList";
import RestarentItemReview from "../pages/restaurants/RestarentItemReview";
function Router() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="auths">
            <Route path="auth-register" element={<AuthRegister />} />
            <Route path="auth-login" element={<AuthLogin />} />
            <Route path="auth-reset" element={<AuthReset />} />
        </Route>
        <Route path="/" element={<AutenticationCheck />}>
          <Route path="home-ecommerce" element={<HomeEcommerce />} />
          <Route path="restaurant-detail/:restaurant_id" element={<RestaurantDetails/>} />
          <Route path="user-manage">
            <Route path="user-list" element={<UserList />} />
            <Route path="user-cards" element={<UserCards />} />
            <Route path="user-profile/:id" element={<UserProfile />} />
            <Route path="user-edit/:id" element={<UserEdit />} />
          </Route>
          <Route path="seller-manage">
            <Route path="seller-list" element={<SellerList />} />
          </Route>
          <Route path="order-manage">
            <Route path="order-list" element={<OrderList />} />
            <Route path="order-list/:restaurant_id" element={<OrderList />} />
          
          </Route>
          <Route path="order-details/:order_id" element={<OrderDetails />} />
          <Route path="transaction-manage">
            <Route path="transaction-list" element={<TransactionList />} />
          
          </Route>
          
          <Route path="coupons-manage">
            <Route path="coupons-list" element={<CouponsList />} />
            <Route path="coupons-list/:coupon_id" element={<CouponsList />} />
          
          </Route>
          <Route path="surveys-manage">
            <Route path="surveys-list" element={<SurveyList />} />
            <Route path="surveys-list/:survey_id" element={<SurveyList />} />
            <Route path="userSurveys/:survey_id" element={<UserSurveyList />} />
          
          </Route>
          <Route path="restaurants-manage">
            <Route path="restaurants-list" element={<RestaurantsList />} />
            <Route path="restaurants-list/:restaurant_id" element={<RestaurantsList />} />
            <Route path="item_reviews/:restaurant_id" element={<RestarentItemReview />} />
          </Route>
          <Route path="cuisines-manage">
            <Route path="cuisines-list" element={<CuisinesList />} />
            <Route path="cuisines-list/:cuisine_id" element={<CuisinesList />} />
          
          </Route>
          <Route path="categories-manage">
            <Route path="categories-list" element={<CategoriesList />} />
            <Route path="categories-list/:category_id" element={<CategoriesList />} />
        
          
          </Route>
          <Route path="admin">
            <Route path="profile" element={<Profile />} />
            <Route path="profile-settings" element={<ProfileSettings />} />
          </Route>

          <Route>
            <Route path="delivery-boys-list" element={<DeliveryBoysList />}/>
          </Route>

          <Route path="ecommerce">
            <Route path="products" element={<Products />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="edit-product/:id" element={<EditProduct />} />
            <Route path="order-details/:order-id" element={<OrderDetails />} />
          </Route>
          <Route path="message_user" element={<MessageToUser />} />
          <Route path="price_change_list" element={<PriceMessages />} />
          <Route path="delivery_charges" element={<DeliveryCharges />} />
          <Route path="dcp" element={<Dcp />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default Router;
