import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { MdDelete, MdVerified } from "react-icons/md";
import { MdThumbUp } from "react-icons/md";
import { handleDeleteSurvey } from "../../pages/survey-manage/SurveyList";

export const userColumns =({ setSurveysData }) => {
    return[
    {
        name: "Survey ID",
        selector: (row) => row.survey.survey_id,
        cell: (row) => (
            <span>{row.survey.survey_id}</span>
        ),
        sortable: true,
    },
    {
        name: "Question",
        selector: (row) => row.survey.question,
        cell: (row) => (
            <span>{row.survey.question}</span>
        ),
        sortable: true,
        minWidth: "400px",
    },
    {
        name: "Users",
        // selector: (row) => row.survey.question,
        cell: (row) => (
            <Link to={`/Surveys-manage/userSurveys/${row.survey.survey_id}`}>
            Users
        </Link>
        ),
        sortable: true,
        minWidth: "100px",
    },
    {
        name: "Answers",
        selector: (row) => row.survey.SurveyAnswers,
        cell: (row) => {
            const menuStyle = {
                backgroundColor: "rgb(221 218 218)",
                borderColor: "#d4d4d4",
                padding: "5px 8px",
                borderRadius: "8px",
                marginBottom: '8px',
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "14px",
                display: "flex",
                justifyContent: "space-between",
                minWidth: "150px",
                maxWidth: "300px",
            };

            return (
                <div >
                    <div style={{ maxHeight: "80px",  minWidth: "400px", overflow: "auto" }}>
                        {Array.isArray(row.survey.SurveyAnswers) && row.survey.SurveyAnswers.length > 0 ? (
                            row.survey.SurveyAnswers.map((item, index) => (
                                <div style={menuStyle} key={index}>
                                    <p className="strong-item-margin">
                                        <strong>{item.answer}</strong>
                                    </p>
                                    <div style={{display:"flex",alignItems:"center",fontSize:"12px" }}>
                                    {item.votes}  
                                    <div style={{ width: "18px", height: "20px", borderRadius: "4px", textAlign: "center", color: "rgb(45, 197, 140)" }}>
                                      <MdThumbUp/>  
                                    </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Options available</p>
                        )}
                    </div>

                </div>
            );
        },
        sortable: true,
        minWidth: "400px",
    },
    {
        name: "Status",
        selector: (row) => row.survey.status,
        cell: (row) => {
            const otherStatusStyle = {
                marginBottom: "15px",
                color: "#c54b2d",
                backgroundColor: "#f8e9e6",
                borderColor: "transparent",
                padding: "5px",
                borderRadius: "6px",
            };

            const Inactive = {
                marginBottom: "15px",
                color: "#2dc58c",
                backgroundColor: "#e6f8f1",
                borderColor: "transparent",
                padding: "5px",
                borderRadius: "6px",
            };

            return (
                <span style={row.survey.status === '"Active"' ? otherStatusStyle : Inactive}>
                    {row.survey.status}
                </span>
            );
        },
        sortable: true,
        
    },
    {
        name: "Created at",
        selector: (row) => row.survey.createdAt,
        cell: (row) => (
            <span>{new Date(row.survey.createdAt).toDateString()}</span>
        ),
        sortable: true,
        minWidth: "150px",
    },
    {
        name: "Updated at",
        selector: (row) => row.survey.updatedAt,
        cell: (row) => (
            <span>{new Date(row.survey.updatedAt).toDateString()}</span>
        ),
        sortable: true,
        minWidth: "150px",
    },
    {
        name: "action",
        cell: (row) => (
            <div className="text-end w-100">
                <Dropdown>
                    <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                        <Icon name="more-v"></Icon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                        <div className="dropdown-content py-1">
                            <LinkList className="link-list-hover-bg-primary link-list-md">
                                <LinkListItem to={`/surveys-manage/surveys-list/${row.survey.survey_id}`}>
                                    <Icon name="edit"></Icon><span>Edit</span>
                                </LinkListItem>

                                <LinkListItem to="#" className="delete-ittem" onClick={() => handleDeleteSurvey(row.survey.survey_id, setSurveysData)}>
                 <MdDelete/><span>Delete</span>
                </LinkListItem>
                            </LinkList>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        ),
        sortable: false,

    },
   


]


};