import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { MdDelete, MdVerified } from "react-icons/md";
import { handleDeleteCategory, handleToggleEnable } from "../../pages/categories-manage/CategoriesList";
import { MdDisabledByDefault } from "react-icons/md";
export const userColumns = ({ setCategoriesData }) => {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    return [
        {
            name: "Category ID",
            selector: (row) => row.category_id,
            cell: (row) => (
                <span>{row.category_id}</span>
            ),
            sortable: true,
        },

        {
            name: "Category Name",
            selector: (row) => row.name,
            cell: (row) => (
                <MediaGroup>
                    <Media className='rest-image-last' size="md" shape="circle" variant={row.theme && row.theme}>
                        {row.image ? (
                            <Image src={`${serverApiUrl}/${row.image}`} staticImage />
                        ) : (
                            ''
                        )}
                    </Media>
                    <MediaText>
                        <span className="small text" style={{ whiteSpace: "nowrap" }}>
                            {row.name}
                        </span>
                    </MediaText>
                </MediaGroup>


            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "Created Date",
            selector: (row) => row.createdAt,
            cell: (row) => (
                <span>{new Date(row.createdAt).toDateString()}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },


        {
            name: "Enable",
            selector: (row) => row.is_enable,
            cell: (row) => {
                const otherStatusStyle = {
                    marginBottom: "15px",
                    backgroundColor: "#e6f8f1",
                    color: "#2dc58c",
                    borderColor: "transparent",
                    padding: "5px",
                    borderRadius: "6px",
                };

                const Inactive = {
                    marginBottom: "15px",
                    color: "#c54b2d",
                    backgroundColor: "#f8e9e6",
                    borderColor: "transparent",
                    padding: "5px",
                    borderRadius: "6px",
                };

                const isEnable = row.is_enable !== undefined ? row.is_enable.toString() : 'N/A';

                return (
                    <span style={row.is_enable ? otherStatusStyle : Inactive}>
                        {isEnable}
                    </span>
                );
            },
            sortable: true,
        },

        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    <LinkListItem to={`/categories-manage/categories-list/${row.category_id}`}>
                                        <Icon name="edit"></Icon><span>Edit</span>
                                    </LinkListItem>
                                    <LinkListItem to="#"  className="delete-ittem" onClick={() => handleToggleEnable(row.category_id, row.is_enable, setCategoriesData)}>
                                    <MdDisabledByDefault />
                                        <span>{row.is_enable ? 'Disable' : 'Enable'}</span>
                                    </LinkListItem>
                                    <LinkListItem  className="delete-ittem" to="#" onClick={() => handleDeleteCategory(row.category_id, setCategoriesData)}>
                                    <MdDelete/><span>Delete</span>
                                    </LinkListItem>
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,

        },
    ]
};


