import { Row, Col, Card, Button, Dropdown, Table, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Colors } from '../../utilities/index';
import hexRGB from '../../utilities/hexRGB';
import { Link ,useNavigate} from 'react-router-dom';
import {  Form} from 'react-bootstrap';
import { Icon, Select ,CustomDropdownToggle,CustomDropdownMenu,LinkList, LinkListItem} from '../../components';
// import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";

function Dcp() {

  const serverApiUrl = process.env.REACT_APP_API_URL;
  const [cuisinesData, setCuisinesData] = useState([]);
  const [allusers, setAllusers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [dcp, setDcp] = useState('');
  const [dcpPercentage, setDcpPercentage] = useState('');
  const [editingCuisine, setEditingCuisine] = useState(null);
  const Navigate = useNavigate();
  const handleCloseModal = () => {
        setShowModal(false);
        Navigate('/dcp')
        setDcp('');
        setDcpPercentage('');
       
        setEditingCuisine('');
        setFieldErrors({
            dcp: false,  
            dcpPercentage: false, 
        });
    };
    const handleEditCharge = (item) => {
        setShowModal(true);
        setDcp(item.dcp);
        setDcpPercentage(item.dcp_percentage);
      
        setFieldErrors({
            dcp: false,  
            dcpPercentage: false,  
        });  
        setEditingCuisine(item); 
    };
    const [fieldErrors, setFieldErrors] = useState({
        dcp: false,
        dcpPercentage: false  
    });
    const validateForm = () => {
        const errors = {};

        if (!dcp) {
            errors.dcp = true;
        }
        if (!dcpPercentage) {
            errors.dcpPercentage = true;
        }
       
        setFieldErrors(errors);

        if (Object.values(errors).some((error) => error)) {
           
            return false;
        }

        return true;
    };
    const postCuisineData = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const payload = {
                dcp:dcp,dcp_percentage:dcpPercentage,
            };
            let response;

            if (editingCuisine) {

                response = await axios.put(`${serverApiUrl}/api/DeliveryPercentage/update/${editingCuisine.p_id}`, payload);
            } else {

                response = await axios.post(`${serverApiUrl}/api/DeliveryPercentage/create`, payload);
            }
            console.log("Cuisine created successfully:", response.data);

            const updatedResponse = await axios.get(`${serverApiUrl}/api/DeliveryPercentage`);
            setAllusers(updatedResponse.data.rows);

            
            handleCloseModal();
            setDcp('');
            setDcpPercentage('');
           
        } catch (error) {
            console.error("Error creating coupon:", error);
        }
    };
  useEffect(() => {
    const fetchchargeslist = async () => {
      try {
        const response = await axios.get(`${serverApiUrl}/api/DeliveryPercentage`);
        console.log(response.data);
        setAllusers(response.data.rows);
      
      } catch (error) {
        console.error('Error fetching last ten transactions:', error);
      }
    };

    

    fetchchargeslist();
   
  }, []);
  
  const userColumns = [
    {
      name: "DCP",
      selector: (row) => row.dcp,
      cell: (row) => <span>{row.dcp}</span>,
      sortable: true,
    },
    {
        name: "Dcp Percentage",
        selector: (row) => row.dcp_percentage,
        cell: (row) => <span>{row.dcp_percentage}</span>,
        sortable: true,
        minWidth: "150px",
      },
  
    {
        name: "action",
        cell: (row) => (
            <div className="text-center">
                 <Button variant="primary"onClick={() => handleEditCharge(row)} >Edit</Button>
               
            </div>
        ),
        sortable: false,

    },
    {
        name: "Created Date",
        selector: (row) => row.createdAt,
        cell: (row) => (
            <span>{new Date(row.createdAt).toDateString()}</span>

        ),
        sortable: true,
        minWidth: "200px",
    },
]

  return (
    <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">DCP</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">DCP Charges</Link></li>
                            </ol>
                        </nav>
                        
                    </Block.HeadContent>
                   
                    {(!allusers || allusers.length === 0) && (
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Charges</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                    )}
                    
                </Block.HeadBetween>
            </Block.Head>
           
             <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={allusers} columns={userColumns} searchList={['dcp','dcp_percentage']} searchTerm = 'Amount'/>
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCuisine ? 'Edit Charges' : 'Add Charges'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor=" categoryName">DCP</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="dcp"
                                            type="text"
                                            placeholder="DCP"
                                            value={ dcp}
                                            onChange={(e) => setDcp(e.target.value)}
                                            className={fieldErrors.dcp ? 'is-invalid' : ''}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    
                                    <Form.Label htmlFor=" categoryName">DCP Percentage</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="dcpPercentage"
                                            type="text"
                                            placeholder="DCP Percentage"
                                            value={ dcpPercentage}
                                            onChange={(e) => setDcpPercentage(e.target.value)}
                                            className={fieldErrors.dcpPercentage ? 'is-invalid' : ''}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                          
                           

                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={postCuisineData}>
                                            {editingCuisine ? 'Update DCP' : 'Add DCP'}
                                        </Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
             
    </Layout>
  )
}

export default Dcp;
