import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { MdVerified } from "react-icons/md";
const serverApiUrl = process.env.REACT_APP_API_URL;
export const userColumns = [
    {
        name: "Order ID",
        selector: (row) => row.order_id,
        cell: (row) => (
            <span>{row.order_id}</span>
        ),
        sortable: true,
      },
      {
        name: "Order Date",
        selector: (row) => row.order_date,
        cell: (row) => (
          <span>{new Date(row.order_date).toDateString()}</span>
        ),
        sortable: true,
        minWidth: "150px",
      },
      
      {
        name: "Restaurant",
        selector: (row) => row.Restaurant.name,
        cell: (row) => (
          <Link to={`/restaurant-detail/${row.restaurant_id}`}>
          {row.Restaurant.name}
        </Link>
        ),
        sortable: true,
        minWidth: "150px",
      },
      {

        name: "Seller Details",
        selector: (row) => row.Restaurant.Seller.full_name,
        cell: (row) => (
          <MediaGroup>
            <Media size="md" shape="circle" className="user-pro" variant={row.theme && row.theme}>
              {row.Restaurant.Seller.profile_picture ? (
                <Image src={`${serverApiUrl}/${row.Restaurant.Seller.profile_picture}`} staticImage />
              ) : (
                <span className="smaller fw-medium initials-container">
                  {row.Restaurant.Seller.full_name && toInitials(row.Restaurant.Seller.full_name)}
                </span>
              )}
            </Media>
            <MediaText>
              <div className="title">
                <p>{row.Restaurant.Seller.full_name}</p>
              </div>
              <span className="small text" style={{ textWrap: "nowrap" }}>{row.Restaurant.Seller.email}</span>
            </MediaText>
          </MediaGroup>
        ),
        sortable: true,
        minWidth: "300px",
      },
      
      {
        name: "Admin Total Commission",
        selector: (row) => row.total_admin_commission,
        cell: (row) => (
            <span>Rs.{row.total_admin_commission}</span>
        ),
        minWidth: "250px",
        sortable: true,
      },
      {
        name: "Total Amount",
        selector: (row) => row.total_amount,
        cell: (row) => (
            <span>Rs.{row.total_amount}</span>
        ),
       
        sortable: true,
      },
      {
        name: "Payble Amount",
        selector: (row) => row.remain_admin_payble_amount,
        cell: (row) => (
            <span>Rs.{row.remain_admin_payble_amount}</span>
        ),
        minWidth: "150px",
        sortable: true,
      },
      {
        name: "Payment Status",
        minWidth: "150px",
        selector: (row) => row.payment_status,
        cell: (row) => {
          const pendingStyle = {
            marginBottom: "15px",
            color: "#c54b2d",
            backgroundColor: "#f8e9e6",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          const otherStatusStyle = {
            marginBottom: "15px",
            color: "#2dc58c",
            backgroundColor: "#e6f8f1",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          return (
            <span style={row.payment_status === 'pending' ? pendingStyle : otherStatusStyle}>
              {row.payment_status}
            </span>
          );
        },
        sortable: true,
      },
      
      {
        name: "Order Status",
        minWidth: "150px",
        selector: (row) => row.order_status,
        cell: (row) => {
          const deliveredStyle = {
            marginBottom: "15px",
            color: "#2dc58c",
            backgroundColor: "#e6f8f1 ",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          const otherStatusStyle = {
            marginBottom: "15px",
            color: "#c54b2d",
            backgroundColor: "#f8e9e6",
            borderColor: "transparent",
            padding: "5px",
            borderRadius: "6px",
          };
      
          return (
            <span style={row.order_status === 'Delivered' ? deliveredStyle : otherStatusStyle}>
              {row.order_status}
            </span>
          );
        },
        sortable: true,
      },
      
    ];
  

