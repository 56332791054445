import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Icon, Select } from '../../components';
import { userColumns } from '../../store/cuisines/CuisinesData';

import axios from 'axios';
import instance from '../../api';
function CuisinesList() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { cuisine_id } = useParams();
    // console.log('kjkj', cuisine_id)
    const [showModal, setShowModal] = useState(false);

    const [cuisinesData, setCuisinesData] = useState([]);
    const handleShowModal = () => setShowModal(true);

    const [cuisineName, setCuisineName] = useState('');
    const [editingCuisine, setEditingCuisine] = useState(null);
    const Navigate = useNavigate();
    const handleCloseModal = () => {
        setShowModal(false);
        Navigate('/cuisines-manage/cuisines-list')
        setCuisineName('');
        setEditingCuisine('');
        setFieldErrors({
            cuisineName: false,

        });
    };
    const [fieldErrors, setFieldErrors] = useState({
        cuisineName: false,

    });
    const validateForm = () => {
        const errors = {};

        if (!cuisineName) {
            errors.cuisineName = true;
        }



        setFieldErrors(errors);

        if (Object.values(errors).some((error) => error)) {

            return false;
        }

        return true;
    };
    const fetchCuisineDetails = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/CuisineType/${cuisine_id}`);
            const cuisine = response.data;
            console.log(cuisine)
            setCuisineName(cuisine.cuisine_type || '');


            setEditingCuisine(cuisine);

            handleShowModal();
        } catch (error) {
            console.error('Error fetching cuisine details:', error);
        }
    };



    const postCuisineData = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const payload = {
                cuisine_type: cuisineName,
            };
            let response;

            if (editingCuisine) {

                response = await axios.put(`${apiUrl}/api/CuisineType/update/${editingCuisine.cuisine_id}`, payload);
            } else {

                response = await instance.post(`/CuisineType/create`, payload);
            }
            console.log("Cuisine created successfully:", response.data);

            const updatedResponse = await axios.get(`${apiUrl}/api/CuisineType`);
            setCuisinesData(updatedResponse.data.rows);


            handleCloseModal();
            setCuisineName('');

        } catch (error) {
            console.error("Error creating coupon:", error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/CuisineType`);
                setCuisinesData(response.data.rows);
                console.log(response.data.rows)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        if (cuisine_id) {
            fetchCuisineDetails();
        }
    }, [cuisine_id]);



    const cuisineColumns = userColumns({ setCuisinesData })

    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Cuisines List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Cuisines Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Cuisines</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Cuisine</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={cuisinesData} columns={cuisineColumns} searchList={['cuisine_type']} searchTerm='Cuisine' />
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCuisine ? 'Edit Cuisine' : 'Add Cuisine'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor=" categoryName">Cuisine Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id=" cuisineName"
                                            type="text"
                                            placeholder="Cuisine Type"
                                            value={cuisineName}
                                            onChange={(e) => setCuisineName(e.target.value)}
                                            className={fieldErrors.cuisineName ? 'is-invalid' : ''}
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}>{fieldErrors.cuisineName ? 'Cuisine Name is Required' : null}</p>
                                    </div>
                                </Form.Group>
                            </Col>


                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={postCuisineData}>
                                            {editingCuisine ? 'Update Cuisine' : 'Add Cuisine'}
                                        </Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export const handleDeleteCuisine = async (cuisine_id, setCuisinesData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const confirmed = window.confirm('Are you sure you want to delete this category?');
    if (confirmed) {
        try {
            await axios.delete(`${apiUrl}/api/CuisineType/delete/${cuisine_id}`);
            console.log(`Cuisine with ID ${cuisine_id} deleted successfully.`);
            const updatedResponse = await axios.get(`${apiUrl}/api/CuisineType`);
            setCuisinesData(updatedResponse.data.rows);
        } catch (error) {
            console.error(`Error deleting cuisine with ID ${cuisine_id}:`, error);


            console.error(error.response);
            console.error(error.message);
        }
    }
};


export default CuisinesList;