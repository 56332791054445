import { Link } from "react-router-dom";
import {
  Tab,
  Nav,
  Card,
  Button,
  Alert,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import Swal from "sweetalert2/src/sweetalert2.js";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Image,
  Icon,
  Media,
  MediaGroup,
  MediaText,
  MediaAction,
  Select,
} from "../../components";

function ProfileSettingsPage() {
  // delete account alert
  const alertConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted", "Your account has been deleted.", "success");
      } else {
        Swal.fire("Cancelled", "Your account is still intact", "info");
      }
    });
  };

  return (
    <Layout title="My Settings" content="container">
      <Block.Head>
        <Block.HeadBetween className="align-items-start">
          <Block.HeadContent>
            <div className="d-flex flex-column flex-md-row align-items-md-center">
              <Media size="huge" shape="circle">
                <Image
                  src="/images/avatar/profile.png"
                  staticImage
                  thumbnail
                  alt="user"
                />
              </Media>
              <div className="mt-3 mt-md-0 ms-md-3">
                <h3 className="title mb-1">Super Admin</h3>
                <span className="small">Owner & Founder</span>
              </div>
            </div>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block className="mt-4">
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Edit Profile</h4>
              <Form action="#">
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Full Name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="fullname"
                          placeholder="Full name"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Email address</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="Email address"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="company">Contact no</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="company"
                          placeholder="Company name"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="oldPassword">
                        Old Password
                      </Form.Label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          className="form-control-icon end password-toggle"
                          title="Toggle show/hide password"
                        >
                          <Icon name="eye-off" className="on"></Icon>
                          <Icon name="eye" className="off"></Icon>
                        </a>
                        <Form.Control type="text" placeholder="Old password" />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="newPassword">
                        New Password
                      </Form.Label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          className="form-control-icon end password-toggle"
                          title="Toggle show/hide password"
                        >
                          <Icon name="eye-off" className="on"></Icon>
                          <Icon name="eye" className="off"></Icon>
                        </a>
                        <Form.Control type="text" placeholder="New password" />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="confirmPassword">
                        Confirm New Password
                      </Form.Label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          className="form-control-icon end password-toggle"
                          title="Toggle show/hide password"
                        >
                          <Icon name="eye-off" className="on"></Icon>
                          <Icon name="eye" className="off"></Icon>
                        </a>
                        <Form.Control
                          type="text"
                          placeholder="Confirm New Password"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
            <Col lg="12 mt-3">
              <Button variant="primary" type="submit">
                Update Profile
              </Button>
            </Col>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default ProfileSettingsPage;
