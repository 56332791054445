import { Row, Col, Card, Button, Dropdown, Table, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Colors } from '../../utilities/index';
import hexRGB from '../../utilities/hexRGB';
import { Link ,useNavigate} from 'react-router-dom';

import { useParams } from 'react-router-dom';
function UserSurveyList() {
  const { survey_id } = useParams();
  const serverApiUrl = process.env.REACT_APP_API_URL;
  const [surveryName, setSurveryName] = useState('');
  const [allusers, setAllusers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [dcp, setDcp] = useState('');
  const [dcpPercentage, setDcpPercentage] = useState('');
  const [editingCuisine, setEditingCuisine] = useState(null);
  const Navigate = useNavigate();
  
    
  
  useEffect(() => {
    const fetchchargeslist = async () => {
      try {
        const response = await axios.get(`${serverApiUrl}/api/Survey/userSurvey/${survey_id}`);
        console.log(response.data[0].question);
        console.log(response.data[0].UserSurveys);
        setAllusers(response.data[0].UserSurveys);
        setSurveryName(response.data[0].question);
      
      } catch (error) {
        console.error('Error fetching last ten transactions:', error);
      }
    };

    

    fetchchargeslist();
   
  }, []);
  
  const userColumns = [
    {
      name: "User Name",
      selector: (row) => row.User.full_name,
      cell: (row) => <span>{row.User.full_name}</span>,
      sortable: true,
    },
    {
        name: "Phone NUmber",
        selector: (row) => row.User.phone_number,
        cell: (row) => <span>{row.User.phone_number}</span>,
        sortable: true,
      },
    {
        name: "Answer",
        selector: (row) => row.SurveyAnswer.answer,
        cell: (row) => <span>{row.SurveyAnswer.answer}</span>,
        sortable: true,
        minWidth: "150px",
      },
  
   
    {
        name: "Created Date",
        selector: (row) => row.createdAt,
        cell: (row) => (
            <span>{new Date(row.createdAt).toDateString()}</span>

        ),
        sortable: true,
        minWidth: "200px",
    },
]

  return (
    <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Question Survey Users List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                            </ol> 
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item">Question: {surveryName}</li>
                            </ol>
                        </nav>
                        
                    </Block.HeadContent>
                   
                  
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                            <Link to={`/Surveys-manage/Surveys-list`}>  Back </Link>
                            </li>
                           
                        </ul>
                    </Block.HeadContent>
                   
                    
                </Block.HeadBetween>
            </Block.Head>
           
             <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={allusers} columns={userColumns} searchList={[]} searchTerm = '' noSearch />
                </Card>
            </Block>

             
    </Layout>
  )
}

export default UserSurveyList;
