import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
function Schedule({ className, ...props }) {
  const compClass = classNames({
    'nk-schedule': true,
    [className]: className,
  });

  return <ul className={compClass}>{props.children}</ul>;
}

function ScheduleItem({ symbol, flush, grow, contentClass, type,notification,handleNotificationLinkClick, className, ...props }) {
  const symbolClass = classNames({
    'nk-schedule-symbol': true,
    [symbol]: symbol,
  });

  const contentClasss = classNames({
    'nk-schedule-content': true,
    'viewed-notification': notification.is_view,
    'unviewed-notification': !notification.is_view,
  });
  const itemClass = classNames('nk-schedule-item', className);

  return (
    <li className={itemClass}>
      <div className="nk-schedule-item-inner">
        <div className={symbolClass}></div>
        <div className={contentClasss}>
 
        <Link to={`/order-details/${notification.order_id}`} onClick={handleNotificationLinkClick}>
        <span className="smaller">{new Date(notification.createdAt).toLocaleString([], { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
            <div className="h6">{notification.notification_id}-{notification.message}</div>
            
          </Link>
        </div>
      </div>
    </li>
  );
}

Schedule.Item = ScheduleItem;

export default Schedule;
