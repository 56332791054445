import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json'
import { Button } from 'react-bootstrap';

import DataTablePagination from '../Pagination/DataTablePagination';
import instance from '../../api';



// export file component
const Export = ({ data }) => {
  const fileName = "download_data";

  const columnsToExclude = ['Useraddresses', 'UserOrders', 'fcm_token', 'password', 'User', 'last_login', 'last_login', 'total_spent'];
  const filterData = (data1) => {
    return data1.map((row) => {
      const filteredRow = { ...row };

      // Remove the unwanted columns
      columnsToExclude.forEach((col) => delete filteredRow[col]);
      if (filteredRow?.Restaurant) {
        filteredRow.Restaurant = `${filteredRow?.Restaurant.name}`;
      }
      if (filteredRow?.OrderItems) {
        filteredRow.OrderItems = filteredRow.OrderItems.map(item => {
          return `${item?.Menu?.item_name} (Q:${item?.quantity})`;
        }).join(', '); // Joi

      }
      if (filteredRow?.Useraddress) {
        filteredRow.contact_name = `${filteredRow.Useraddress.contact_name}`;
      }
      if (filteredRow?.Useraddress) {
        filteredRow.contact_phone = `${filteredRow.Useraddress.contact_phone}`;
      }
      if (filteredRow?.Useraddress) {
        filteredRow.Useraddress = `${filteredRow.Useraddress.address_one} ${filteredRow.Useraddress.address_two}`;
      }


      return filteredRow;
    });
  };
  const exportCSV = () => {
    const filteredData = filterData(data);
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: filteredData, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="export-options d-flex align-items-center me-2">
      {/* <div className="export-title small me-2"> </div> */}
      <div className="btn-group">
        <Button variant="outline-light" onClick={() => exportCSV()}>
          Download CSV
        </Button>
        {/* <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button> */}
      </div>
    </div>
  );
};

const DownloadOrdersButton = () => {
  const [loading, setLoading] = useState(false);

  const downloadExcel = async () => {
    setLoading(true);
    try {
      const response = await instance.get("/Admin/DownloadOrdersData", {
        responseType: 'blob', // Treat the response as a Blob
      });
      downloadExportDatapublic(response.data);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const downloadExportDatapublic = (data) => {
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "orders.xlsx"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
    }
  };

  return (<>
    <div className="export-options d-flex align-items-center me-2">
      {/* <div className="export-title small me-2"> </div> */}
      <div className="btn-group">
        <Button variant="outline-light" onClick={downloadExcel} disabled={loading}>
          {loading ? 'Downloading...' : 'Download Orders Excel'}
        </Button>
        {/* <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button> */}
      </div>
    </div>
    {/* <button onClick={downloadExcel} disabled={loading}>
      {loading ? 'Downloading...' : 'Download Orders Excel'}
    </button> */}
  </>
  );
};

const DownloadUsersButton = () => {
  const [loading, setLoading] = useState(false);

  const downloadExcel = async () => {
    setLoading(true);
    try {
      const response = await instance.get("/Admin/DownloadUsersData", {
        responseType: 'blob', // Treat the response as a Blob
      });
      downloadExportDatapublic(response.data);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const downloadExportDatapublic = (data) => {
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.xlsx"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
    }
  };

  return (<>
    <div className="export-options d-flex align-items-center me-2">
      {/* <div className="export-title small me-2"> </div> */}
      <div className="btn-group">
        <Button variant="outline-light" onClick={downloadExcel} disabled={loading}>
          {loading ? 'Downloading...' : 'Download users Excel'}
        </Button>
        {/* <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button> */}
      </div>
    </div>
    {/* <button onClick={downloadExcel} disabled={loading}>
      {loading ? 'Downloading...' : 'Download Orders Excel'}
    </button> */}
  </>
  );
};


// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
));

function DataTableComponent({ userDownload,orderDownload, fetchData, setSeatch, search, setRowsPerPage, rowsPerPages, setpage, page, fullData, data, columns, className, expandableRows, actions, tableClassName, selectableRows, searchTerm, searchList, drill = '', ...props }) {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState('');
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  // console.log("data", data);

  useEffect(() => { setTableData(data) }, [data])
  // filter items by name
  useEffect(() => {
    let filteredData
    if (drill) {
      // filteredData = data.filter(
      //   item => searchList.reduce((total, search) => total || item[drill][search].toLowerCase().includes(searchText.toLowerCase()), false)
      // );
      filteredData = data?.filter(
        item => searchList?.reduce((total, search) => {
          // Check if item[search] is not null or undefined before calling toLowerCase()
          // console.log(search)
          // console.log(item)
          if (searchText) {
            if (item[search]) {
              // console.log(item[search])
              // console.log(item[search].toString())
              return total || item[search].toString().toLowerCase().includes(searchText.toLowerCase());
            } else {

              if (item[drill][search]) {
                return total || item[drill][search].toLowerCase().includes(searchText.toLowerCase());
              } else {
                if (item['Restaurant'][search]) {
                  return total || item['Restaurant'][search].toLowerCase().includes(searchText.toLowerCase());
                } else {
                  return total;
                }

              }
            }
          } else {

            // if (item[drill][search]) {
            //   return total || item[drill][search].toLowerCase().includes(searchText);
            // } else {
            //   return total;
            // }
            // console.log(item[drill][search]);

            if (item[drill][search]) {
              return item
            } else {
              return item;
            }

          }


        }, false)
      );
    } else {
      filteredData = data?.filter(
        item => searchList?.reduce((total, search) => {
          // Check if item[search] is not null or undefined before calling toLowerCase()
          if (item[search]) {
            return total || item[search].toString().toLowerCase().includes(searchText.toLowerCase());
          }
          return total;
        }, false)
      );
      // filteredData = data.filter(
      //   item => searchList.reduce((total, search) => total || item[search].toLowerCase().includes(searchText.toLowerCase()), false)
      // );
    }
    // console.log(filteredData);
    setTableData(filteredData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  // function to change the table design view to expanable under 1200 px
  const inputStyle = {
    width: `${(searchTerm.length + 10) * 10}px`,
  };
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  console.log("tableData", tableData);

  return (
    <div className="data-table-wrapper">
      <div className="data-table-top">
        <div className="data-table-search">
          <input
            className="form-control"
            placeholder={`Search by ${searchTerm}`}
            type="text"
            style={inputStyle}
            value={search ? search : searchText}
            onChange={e => { setSearchText(e.target.value); setSeatch(e.target.value); }}
          />
        </div>
        <div className="data-table-action-wrap">
          {actions && <Export data={tableData} />}
          {orderDownload && <DownloadOrdersButton />}
          {userDownload && <DownloadUsersButton />}
          {fetchData &&
            <button style={{ padding: '10px', backgroundColor: "#82bc43", border: 'none', color: 'white', borderRadius: '5px' }} onClick={() => fetchData("", '1', '10', "")}>Reload</button>}
          {/* <div className="data-table-select">
            <select
              className="form-select"
              onChange={e => {
                const value = e.target.value;
                setShowItemPerPage(value);
                setRowsPerPage(value); // Update parent state
              }}
              value={rowsPerPages ? rowsPerPages : showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
            <span className="text">Per page</span>
          </div> */}
        </div>
      </div>
      {/* {tableData.length ?  */}
      <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={rowsPerPages || showItemPerPage}
              itemPerPage={rowsPerPages || showItemPerPage}
              totalItems={fullData ? fullData.totalRecords : rowCount}
              paginate={setpage ? newPage => setpage(newPage) : onChangePage}
              currentPage={fullData ? fullData.currentPage : currentPage}
              onChangeRowsPerPage={setRowsPerPage ? newRowsPerPage => setRowsPerPage(newRowsPerPage) : onChangeRowsPerPage}
              setShowItemPerPage={setRowsPerPage ? setRowsPerPage : setShowItemPerPage}
            />
          </div>
        )}
      />
    </div>
  );
}

export default DataTableComponent;
