import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { MdVerified } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { handleDeleteCoupon } from "../../pages/coupons/CouponsList";
import './CouponsData.css';

export const userColumns = ({ setCouponsData }) => {
    return [
        {
            name: "Coupon ID",
            selector: (row) => row.coupon_id,
            cell: (row) => (
                <span>{row.coupon_id}</span>
            ),
            sortable: true,
        },
        {
            name: "start Date",
            selector: (row) => row.start_time,
            cell: (row) => (
                <span>{new Date(row.start_time).toDateString()}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "End Date",
            selector: (row) => row.end_time,
            cell: (row) => (
                <span>{new Date(row.end_time).toDateString()}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "Coupon name",
            selector: (row) => row.name,
            cell: (row) => (
                <span>{row.name}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: " coupon code",
            selector: (row) => row.code,
            cell: (row) => (
                <span>{row.code}</span>
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "Discount type",
            selector: (row) => row.discount_type,
            cell: (row) => (
                <span>{row.discount_type}</span>
            ),

            sortable: true,
        },
        {
            name: "Discount Value",
            selector: (row) => row.discount_value,
            cell: (row) => (
                <span>Rs.{row.discount_value}</span>
            ),

            sortable: true,
        },
        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm custom-dropdown-transform" as={CustomDropdownMenu} align="end" >
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    <LinkListItem to={`/coupons-manage/coupons-list/${row.coupon_id}`}>
                                        <Icon name="edit"></Icon><span>Edit</span>
                                    </LinkListItem>

                                    <LinkListItem className="delete-ittem" to="#" onClick={() => handleDeleteCoupon(row.coupon_id, setCouponsData)}>
                                        <MdDelete /><span>Delete</span>
                                    </LinkListItem>
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,

        },
    ]
};


