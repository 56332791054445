import { Row, Col, Card, Button, Dropdown, Table, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Colors } from '../../utilities/index';
import hexRGB from '../../utilities/hexRGB';
import { Link ,useNavigate} from 'react-router-dom';
import {  Form} from 'react-bootstrap';
import { Icon, Select ,CustomDropdownToggle,CustomDropdownMenu,LinkList, LinkListItem} from '../../components';
// import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";

function DeliveryCharges() {

  const serverApiUrl = process.env.REACT_APP_API_URL;
  const [cuisinesData, setCuisinesData] = useState([]);
  const [allusers, setAllusers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [orderCharge, setOrderCharge] = useState('');
  const [editingCuisine, setEditingCuisine] = useState(null);
  const Navigate = useNavigate();
  const handleCloseModal = () => {
        setShowModal(false);
        Navigate('/delivery_charges')
        setMinAmount('');
        setMaxAmount('');
        setOrderCharge('');
        setEditingCuisine('');
        setFieldErrors({
            minAmount: false,  
            maxAmount: false,  
            orderCharge: false,  
        });
    };
    const handleEditCharge = (item) => {
        setShowModal(true);
        setMinAmount(item.min_amount);
        setMaxAmount(item.max_amount);
        setOrderCharge(item.charge_amount);
      
        setFieldErrors({
            minAmount: false,  
            maxAmount: false,  
            orderCharge: false,  
        });  
        setEditingCuisine(item); 
    };
    const [fieldErrors, setFieldErrors] = useState({
        minAmount: false,
        maxAmount: false,
        orderCharge: false,   
    });
    const validateForm = () => {
        const errors = {};

        if (!minAmount) {
            errors.minAmount = true;
        }
        if (!maxAmount) {
            errors.maxAmount = true;
        }
        if (!orderCharge) {
            errors.orderCharge = true;
        }

        

        setFieldErrors(errors);

        if (Object.values(errors).some((error) => error)) {
           
            return false;
        }

        return true;
    };
    const postCuisineData = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const payload = {
                min_amount:minAmount,max_amount:maxAmount,charge_amount:orderCharge,
            };
            let response;

            if (editingCuisine) {

                response = await axios.put(`${serverApiUrl}/api/DeliveryCharges/update/${editingCuisine.charge_id}`, payload);
            } else {

                response = await axios.post(`${serverApiUrl}/api/DeliveryCharges/create`, payload);
            }
            console.log("Cuisine created successfully:", response.data);

            const updatedResponse = await axios.get(`${serverApiUrl}/api/DeliveryCharges`);
            setAllusers(updatedResponse.data.rows);

            
            handleCloseModal();
            setMinAmount('');
            setMaxAmount('');
            setOrderCharge('');
           
        } catch (error) {
            console.error("Error creating coupon:", error);
        }
    };
  useEffect(() => {
    const fetchchargeslist = async () => {
      try {
        const response = await axios.get(`${serverApiUrl}/api/DeliveryCharges`);
        console.log(response.data);
        setAllusers(response.data.rows);
      
      } catch (error) {
        console.error('Error fetching last ten transactions:', error);
      }
    };

    

    fetchchargeslist();
   
  }, []);
  
  const userColumns = [
    {
      name: "Minimum Order Amount",
      selector: (row) => row.min_amount,
      cell: (row) => <span>{row.min_amount}</span>,
      sortable: true,
    },
    {
        name: "Maximum Order Amount",
        selector: (row) => row.max_amount,
        cell: (row) => <span>{row.max_amount}</span>,
        sortable: true,
        minWidth: "150px",
      },
      {
        name: "Delivery Charge ",
        selector: (row) => row.charge_amount,
        cell: (row) => <span>{row.charge_amount}</span>,
        sortable: true,
        minWidth: "150px",
      },
     
    {
        name: "action",
        cell: (row) => (
            <div className="text-center">
                 <Button variant="primary"onClick={() => handleEditCharge(row)} >Edit</Button>
                 {/* //row.charge_id */}
                
            </div>
        ),
        sortable: false,

    },
    {
        name: "Created Date",
        selector: (row) => row.createdAt,
        cell: (row) => (
            <span>{new Date(row.createdAt).toDateString()}</span>

        ),
        sortable: true,
        minWidth: "200px",
    },
]

  return (
    <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Delivery Charges</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Delivery Charges List</Link></li>
                            </ol>
                        </nav>
                        
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Charges</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                    
                </Block.HeadBetween>
            </Block.Head>
           
             <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={allusers} columns={userColumns} searchList={['min_amount','max_amount','charge_amount']} searchTerm = 'Amount'/>
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCuisine ? 'Edit Charges' : 'Add Charges'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor=" categoryName">Minimum Order Amount</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id=" minAmount"
                                            type="text"
                                            placeholder="Minimum Order Amount"
                                            value={ minAmount}
                                            onChange={(e) => setMinAmount(e.target.value)}
                                            className={fieldErrors.minAmount ? 'is-invalid' : ''}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    
                                    <Form.Label htmlFor=" categoryName">Maximum Order Amount</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="maxAmount"
                                            type="text"
                                            placeholder="Maximum Order Amount"
                                            value={ maxAmount}
                                            onChange={(e) => setMaxAmount(e.target.value)}
                                            className={fieldErrors.maxAmount ? 'is-invalid' : ''}
                                        />
                                    </div>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                   
                                    <Form.Label htmlFor=" categoryName">Delivery Charge</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="orderCharge"
                                            type="text"
                                            placeholder="Delivery Charge Amount"
                                            value={ orderCharge}
                                            onChange={(e) => setOrderCharge(e.target.value)}
                                            className={fieldErrors.orderCharge ? 'is-invalid' : ''}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                           

                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={postCuisineData}>
                                            {editingCuisine ? 'Update Charges' : 'Add Charges'}
                                        </Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
             
    </Layout>
  )
}

export default DeliveryCharges;
