import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Layout from '../../layout/fullpage';

import {Media, MediaGroup, Image, OverlineTitle, Logo} from '../../components';

function AuthRegisterPage() {

  return (
    <Layout title="Register" centered>
        <div className="container p-2 p-sm-4">
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                <Row className="g-0 flex-lg-row-reverse">
                    <Col lg="5">
                        <Card.Body>
                            <div className="nk-block-head text-center">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title mb-1">Create New Account</h3>
                                    <p className="small">Use your remail email continue with MomsFoood (it's free)!</p>
                                </div>
                            </div>
                            <Form action="#">
                                <Row className="gy-3">
                                    <Col className="col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="username">Username</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control type="text" id="username" placeholder="Enter username"/>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="email">Email</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control type="email" id="email" placeholder="Enter email address"/>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="password">Password</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control type="text" id="password" placeholder="Enter password"/>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12">
                                        <Form.Check 
                                            className="form-check-sm"
                                            type="checkbox"
                                            id="agreeCheckbox"
                                            label="I agree to privacy policy & terms"
                                        />
                                    </Col>
                                    <Col className="col-12">
                                        <div className="d-grid">
                                            <Button type="submit">Sign up</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="text-center mt-4">
                                <p className="small">Already have an account? <Link to="/auths/auth-login">Login</Link></p>
                            </div>
                        </Card.Body>
                    </Col>
                    <Col lg="7">
                        <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-center">
                            <div className="mask mask-1"></div>
                            <div className="brand-logo">
                                <Logo />
                            </div>
                            <div className="row">
                                <div className="col-sm-11">
                                    <div className="mt-4">
                                        <div className="h1 title mb-3">Register for an Account</div>
                                        <p>Sign up now to unlock exclusive features. Join our community and experience a seamless registration process for a personalized journey.</p>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>
    </Layout>
  )
}

export default AuthRegisterPage;
